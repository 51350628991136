import React from 'react'
import PropTypes from 'prop-types'
import { useLocation, Link } from 'react-router-dom'
import { T7FH } from '../ui/Typography'

export const useBreadcrumb = () => {
  const location = useLocation()
  const pathnames = location.pathname.split('/').filter((x) => x)
  const breadcrumbs = [{ name: 'Home', path: '/' }].concat(
    pathnames.map((path, index) => {
      const pathSoFar = `/${pathnames.slice(0, index + 1).join('/')}`
      let displayName = path.replace(path[0], path[0].toUpperCase())
      if (path === 'research') {
        displayName = 'Research Repository'
      }
      return {
        name: displayName,
        path: pathSoFar,
      }
    }),
  )
  return breadcrumbs
}

export const BreadcrumbItem = ({ name, path, isLast }) => (
  <T7FH color="#67481D">
    {!isLast ? (
      <Link to={path} style={{ color: 'inherit', textDecoration: 'none' }}>
        {name}
      </Link>
    ) : (
      name
    )}
    {!isLast && <span>{' >'}&nbsp;</span>}
  </T7FH>
)

BreadcrumbItem.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string,
  isLast: PropTypes.bool.isRequired,
}

BreadcrumbItem.defaultProps = {
  path: '/',
}
