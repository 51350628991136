import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const DropdownMenu = ({ options, placeholder, onOptionChange }) => {
  const [isOpen, setIsOpen] = useState(false)
  const defaultOption = placeholder.split(': ')[1] || options[0]
  const [selectedOption, setSelectedOption] = useState(defaultOption)

  const toggleDropdown = () => setIsOpen(!isOpen)

  const handleOptionClick = (option) => {
    setSelectedOption(option)
    setIsOpen(false)
    onOptionChange(option)
  }

  return (
    <DropdownContainer>
      <DropdownButton onClick={toggleDropdown}>
        {`Sort by: ${selectedOption}`}
      </DropdownButton>
      {isOpen && (
        <DropdownListContainer>
          <DropdownList>
            {options.map((option) => (
              <DropdownListItem
                key={option} // Assuming each option is a unique string
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </DropdownListItem>
            ))}
          </DropdownList>
        </DropdownListContainer>
      )}
    </DropdownContainer>
  )
}

DropdownMenu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string,
  onOptionChange: PropTypes.func.isRequired,
}

DropdownMenu.defaultProps = {
  placeholder: 'Select an option...',
}

export default DropdownMenu

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  font-size: 1em; // Adjust as per your UI standards
`

const DropdownButton = styled.button`
  padding: 0.5em 1em;
  border: none; // Remove the border
  background-color: #fff;
  border-radius: 0.25em;
  cursor: pointer;
  font-size: 1em; // Adjust as per your UI standards
  color: #5a430e;
  font-weight: bold; // Make the font bold
  &:focus {
    outline: none;
  }
`

const DropdownListContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 2px solid #ddd;
  border-radius: 0.25em;
  background-color: #ffffe0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`

const DropdownList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const DropdownListItem = styled.li`
  padding: 0.5em 1em;
  cursor: pointer;
  color: #218838;
  &:hover {
    background-color: #f2f2f2;
  }
`
