import './App.css'
import { QueryClientProvider } from '@tanstack/react-query'
import AppRouter from './AppRouter'
import { queryClient } from './config/QueryClient' // Ensure this matches the export
import { AuthProvider } from './context/AuthContext'

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AppRouter />
      </AuthProvider>
    </QueryClientProvider>
  )
}

export default App
