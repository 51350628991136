import React from 'react'
import styled from 'styled-components'
import {
  useBreadcrumb,
  BreadcrumbItem,
} from '../../components/common/form/Address'

const Research = () => {
  const items = useBreadcrumb()
  return (
    <ResearchContainer>
      <HeaderContainer>
        <AddressContainer>
          {items.map((item, index) => (
            <BreadcrumbItem
              key={item.name}
              name={item.name}
              path={item.path}
              isLast={index === items.length - 1}
            />
          ))}
        </AddressContainer>
      </HeaderContainer>
    </ResearchContainer>
  )
}

export default Research

const ResearchContainer = styled.div`
  margin-bottom: 50px;
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: column;
  margin-bottom: 30px;
`

const AddressContainer = styled.div`
  display: flex;
  justify-content: center;
`
