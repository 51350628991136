import styled, { css } from 'styled-components'
import { size } from '../../../assets/styles/mediaBreakpoints'

const TypographyStyles = css`
  font-family: ${(props) => props.font || 'Poppins'};
  color: ${(props) => props.color};
  font-size: ${(props) => props.size || '19px'};
  font-weight: ${(props) => props.weight || '400'};
  text-align: ${(props) => props.align || 'left'};
  padding: ${(props) => props.padding || '0'};

  @media (max-width: ${size.tablet}) {
    font-size: ${(props) => props.mobileSize || props.size || '14px'};
  }

  ${(props) => props.style || {}}
`

export const H1 = styled.h1`
  ${TypographyStyles}
  font-size: ${(props) => props.size || '38px'};

  @media (max-width: ${size.tablet}) {
    font-size: ${(props) => props.mobileSize || props.size || '24px'};
  }
`

export const H2 = styled.h2`
  ${TypographyStyles}
  font-size: ${(props) => props.size || '24px'};
  @media (max-width: ${size.tablet}) {
    font-size: ${(props) => props.mobileSize || props.size || '22px'};
  }
`

export const H3 = styled.h3`
  ${TypographyStyles}
  font-size: ${(props) => props.size || '20px'};
  @media (max-width: ${size.tablet}) {
    font-size: ${(props) => props.mobileSize || props.size || '18px'};
  }
`

export const P = styled.p`
  ${TypographyStyles}
  margin: ${(props) => props.margin || '0'};
  font-size: ${(props) => props.size || '16px'};
  @media (max-width: ${size.tablet}) {
    font-size: ${(props) => props.mobileSize || props.size || '14px'};
  }
`

export const Span = styled.span`
  ${TypographyStyles}
  margin: ${(props) => props.margin || '0'};
  @media (max-width: ${size.tablet}) {
    font-size: ${(props) => props.mobileSize || props.size || '16px'};
  }
`

export const SpanKeyword = styled.span`
  ${TypographyStyles}
  font-size: 15px;
  font-weight: 600;
  margin: ${(props) => props.margin || '0'};
  @media (max-width: ${size.tablet}) {
    font-size: ${(props) => props.mobileSize || props.size || '12px'};
  }
`

export const SpanSmall = styled.span`
  ${TypographyStyles}
  font-size: ${(props) => props.size || '12px'};
  text-align: ${(props) => props.align || 'left'};
  margin: ${(props) => props.margin || '0'};

  @media (max-width: ${size.tablet}) {
    font-size: ${(props) => props.mobileSize || props.size || '10px'};
  }
`

export const H1Title = styled.h1`
  ${TypographyStyles}
  font-size: ${(props) => props.size || '32px'};

  @media (max-width: ${size.tablet}) {
    font-size: ${(props) => props.mobileSize || props.size || '24px'};
  }
`

export const T4Content = styled.p`
  font-size: 19px;
  font-weight: 700;
  line-height: 28px;
  margin: ${(props) => props.margin || '0'};
  @media (max-width: ${size.tablet}) {
    font-size: 16px;
  }
`

export const SpanTitle = styled.span`
  font-size: ${(props) => props.size || '24px'};
  font-weight: ${(props) => props.weight || '700'};
  color: ${(props) => props.color || '#2F632C'};
  margin: ${(props) => props.margin || '0'};

  @media (max-width: ${size.tablet}) {
    font-size: ${(props) => props.size || '18px'};
  }
`

export const HomeSDG = styled.h3`
  ${TypographyStyles}
  font-size: ${(props) => props.size || '18px'};
  font-weight: ${(props) => props.weight || '500'};
  color: ${(props) => props.color || '#422a14'};
  @media (max-width: ${size.tablet}) {
    font-size: ${(props) => props.mobileSize || props.size || '18px'};
  }
`
export const T3Content = styled.span`
  font-size: ${(props) => props.size || '26px'};
  font-weight: ${(props) => props.weight || '900'};
  color: ${(props) => props.color || '#2F632C'};

  @media (max-width: ${size.tablet}) {
    font-size: ${(props) => props.size || '18px'};
  }
`
export const SpanTitle3 = styled.span`
  font-size: ${(props) => props.size || '32px'};
  font-weight: ${(props) => props.weight || '700'};
  color: ${(props) => props.color || '#2F632C'};
  margin-left: ${(props) => props.marginLeft || '50px'};

  @media (max-width: ${size.tablet}) {
    font-size: ${(props) => props.size || '18px'};
  }
`
export const T1Content = styled.span`
  ${TypographyStyles}
  font-size: ${(props) => props.size || '24px'};
  font-weight: 700;
  color: ${(props) => props.color || '#8A8A8A'};
  @media (max-width: ${size.tablet}) {
    font-size: ${(props) => props.mobileSize || props.size || '18px'};
  }
`

export const H4Text = styled.h4`
  ${TypographyStyles}
  font-family: 'Roboto', sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 37.5px;
  color: ${(props) => props.color || '#2F632C'};
  margin: ${(props) => props.margin || '0'};
  @media (max-width: ${size.tablet}) {
    font-size: 28px;
  }
`

export const T5Content = styled.p`
  font-size: 19px;
  font-weight: 500;
  line-height: 28px;
  color: ${(props) => props.color || '#2F632C'};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  @media (max-width: ${size.tablet}) {
    font-size: 16px;
  }
`

export const T6Content = styled.p`
  ${TypographyStyles}
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
  margin: ${(props) => props.margin || '0'};
  @media (max-width: ${size.tablet}) {
    font-size: 18px;
    line-height: 28px;
  }
`
export const H3SB = styled.h3`
  ${TypographyStyles}
  font-family: 'Roboto', sans-serif;
  font-size: 38px;
  font-weight: 600;
  margin: ${(props) => props.margin};
  @media (max-width: ${size.tablet}) {
    font-size: 28px;
  }
`

export const T15BContent = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  margin: ${(props) => props.margin || '0'};
  @media (max-width: ${size.tablet}) {
    font-size: 20px;
  }
`

export const T11RContent = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: ${(props) => props.color || '#0A0A0A'};
  margin: ${(props) => props.margin || '0'};
  @media (max-width: ${size.tablet}) {
    font-size: 13px;
  }
`

export const T11SContent = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 13px;
  color: ${(props) => props.color || '#0A0A0A'};
  margin: ${(props) => props.margin || '0'};
  @media (max-width: ${size.tablet}) {
    font-size: 8px;
  }
`

export const T7FH = styled.span`
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  color: ${(props) => props.color || '#B64A07'};
  margin: ${(props) => props.margin || '0'};
  @media (max-width: ${size.tablet}) {
    font-size: ${(props) => props.mobileSize || props.size || '12px'};
  }
`

export const T4FH = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: ${(props) => props.color || '#B64A07'};
  margin: ${(props) => props.margin || '0'};
  @media (max-width: ${size.tablet}) {
    font-size: 10px;
  }
`

export const T10SmallB = styled.span`
  font-size: 12px;
  font-weight: 800;
  line-height: 18px;
  margin: ${(props) => props.margin || '0'};
  @media (max-width: ${size.tablet}) {
    font-size: 10px;
  }
`

export const SSOButtonText = styled.div`
  width: 100%;
  height: 100%;
`

export const SignInAndSignUpLinkText = styled.a`
  color: '#B64A07',
  textDecoration: 'none',
  fontWeight: '600',
`

export const NavigateBack = styled.a`
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px;
  cursor: pointer;
  font-weight: bold;

  @media (max-width: ${size.tablet}) {
    font-size: 0.75rem;
    width: 150px;
  }
`
