import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FcGoogle } from 'react-icons/fc'
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import CoGsLogo from '../../assets/images/CoGs_logo.png'
import Input from '../../components/common/form/Input'
import { size } from '../../assets/styles/mediaBreakpoints'
import { INPUT_TYPES } from '../feed/utils/Constants'
import {
  SpanTitle,
  SpanSmall,
  P,
  SSOButtonText,
  SignInAndSignUpLinkText,
  NavigateBack,
} from '../../components/common/ui/Typography'
import {
  AuthenticationButton,
  SSOButton,
} from '../../components/common/ui/Button'
import {
  Container,
  ComponentContainer,
  ContentContainer,
  SSOContainer,
  InputAndNavigationFieldContainer,
} from '../../components/common/ui/Container'
import { Line, Separator } from '../../components/common/ui/Line'
import axiosInstance from '../../config/AxiosInstance'
import { useAuth } from '../../context/AuthContext'

const SSOComponent = () => {
  return (
    <>
      <SSOContainer>
        <SSOButton>
          <SSOButtonText>
            <SpanSmall size="0.8rem" color="#2F632C">
              Continue with Google
            </SpanSmall>
          </SSOButtonText>
          <FcGoogle style={{ fontSize: '20px' }} />
        </SSOButton>
        <SSOButton>
          <SSOButtonText>
            <SpanSmall size="0.8rem" color="#2F632C">
              Continue with Facebook
            </SpanSmall>
          </SSOButtonText>
          <Icon icon="logos:facebook" style={{ fontSize: '20px' }} />
        </SSOButton>
      </SSOContainer>
      <Separator>
        <Line />
        <SpanSmall margin="0 10px" size="10px">
          OR
        </SpanSmall>
        <Line />
      </Separator>
    </>
  )
}

const InputFieldsComponent = ({ email, setEmail, password, setPassword }) => {
  return (
    <InputAndNavigationFieldContainer>
      <Input
        title="Email Address"
        inputType={INPUT_TYPES.email}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Input
        title="Password"
        inputType={INPUT_TYPES.password}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
    </InputAndNavigationFieldContainer>
  )
}

const NavigationComponent = ({ handleSignin, isValidCredential }) => {
  return (
    <InputAndNavigationFieldContainer>
      {isValidCredential === false && (
        <P color="red" margin="10px">
          Invalid credentials. Please try again.
        </P>
      )}
      <AuthenticationButton onClick={handleSignin}>Log In</AuthenticationButton>
      <NavigateBack href="/">
        <Icon icon="akar-icons:arrow-left" style={{ color: '#2f632c' }} />
        <P margin="10px" weight="600">
          Back
        </P>
      </NavigateBack>
      <AlreadyHaveAccount>
        <SpanSmall>
          Don&apos;t have an account?&nbsp;
          <SignInAndSignUpLinkText href="/SignUp" rel="noreferrer noopener">
            Sign Up
          </SignInAndSignUpLinkText>
        </SpanSmall>
      </AlreadyHaveAccount>
      <ForgotPassword>
        <SignInAndSignUpLinkText
          href="/forgot-password"
          rel="noreferrer noopener"
        >
          Forgot your password?
        </SignInAndSignUpLinkText>
      </ForgotPassword>
    </InputAndNavigationFieldContainer>
  )
}

const SignIn = ({ SSOtoggle }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isValidCredential, setIsValidCredential] = useState(null)
  const { login } = useAuth()
  const navigate = useNavigate()

  const handleSignin = async (event) => {
    event.preventDefault()
    const identifier = email
    try {
      const response = await axiosInstance.post(`/api/auth/local`, {
        identifier,
        password,
      })
      if (response.status === 200) {
        login(response.data)
        navigate('/homefeed')
      }
    } catch (error) {
      setIsValidCredential(false)
      // console.error('Error fetching data:', error)
    }
  }

  return (
    <Container>
      <ComponentContainer>
        <ContentContainer>
          <LogoImage src={CoGsLogo} />
          <SpanTitle size="24px" align="center" margin="20px">
            Welcome back!
            <br />
            Log in to your account
          </SpanTitle>
          {SSOtoggle ? <SSOComponent /> : null}
          <InputFieldsComponent
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
          />
          <NavigationComponent
            handleSignin={handleSignin}
            isValidCredential={isValidCredential}
          />
        </ContentContainer>
      </ComponentContainer>
    </Container>
  )
}

SignIn.propTypes = {
  SSOtoggle: PropTypes.bool.isRequired,
}

NavigationComponent.propTypes = {
  handleSignin: PropTypes.func.isRequired,
  isValidCredential: PropTypes.bool.isRequired,
}

InputFieldsComponent.propTypes = {
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
}

export default SignIn

const LogoImage = styled.img`
  max-width: 200px;
  height: auto;
  margin: 20px;
`

const AlreadyHaveAccount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px 0 0 0;
`

const ForgotPassword = styled.div`
  font-size: 0.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 5px;

  @media (max-width: ${size.tablet}) {
    font-size: 0.5rem;
  }
`
