import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { device } from '../../../assets/styles/mediaBreakpoints'

const RadioButton = ({ label, name, defaultChecked }) => {
  const [checked, setChecked] = useState(defaultChecked)

  const handleChange = () => {
    setChecked(!checked)
  }

  return (
    <Container>
      <Input
        type="radio"
        name={name}
        checked={checked}
        onChange={handleChange}
      />
      <Label>{label}</Label>
    </Container>
  )
}

RadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
}

RadioButton.defaultProps = {
  defaultChecked: false,
}

export default RadioButton

const Container = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1em; // Start with a smaller font size for mobile

  @media ${device.tablet} {
    font-size: 1.5em; // Increase font size for tablets
  }

  @media ${device.desktop} {
    font-size: 2em; // Further increase font size for desktops
  }
`

const Input = styled.input`
  opacity: 0; // Hide the native radio button
  position: absolute;
  width: 1em; // Adjust as needed
  height: 1em; // Adjust as needed

  &:checked + span::before {
    // Style for when the radio is checked
    background-color: green; // Change to your preferred shade of green
  }
`

const Label = styled.span`
  // Add styles for the label
  display: flex;
  align-items: center; // This will vertically center the text with the radio button
  position: relative;
  padding-left: 2em; // Adjust based on the size of your custom radio

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1em; // Size of the custom radio
    height: 1em; // Size of the custom radio
    border-radius: 50%;
    border: 3px solid green; // Border color for the custom radio
    background-color: white;
    box-sizing: border-box;
  }
`
