import styled from 'styled-components'
import { useState } from 'react'
import CoGsLogo from '../../assets/images/CoGs_logo.png'
import { PrimaryButton } from '../../components/common/ui/Button'
import Input from '../../components/common/form/Input'
import { size } from '../../assets/styles/mediaBreakpoints'
import {
  SpanTitle,
  T11RContent,
  T7FH,
} from '../../components/common/ui/Typography'
import axiosInstance from '../../config/AxiosInstance'
import {
  Container,
  ComponentContainer,
  ContentContainer,
} from '../../components/common/ui/Container'

const NavigationComponent = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <LinkWrapper>
        <T11RContent>
          Don&apos;t have an account?&nbsp;
          <Link href="/signup">
            <T7FH>Sign Up</T7FH>
          </Link>
        </T11RContent>
      </LinkWrapper>

      <LinkWrapper>
        <T11RContent>
          Already a user?&nbsp;
          <Link href="/signin">
            <T7FH>Log In</T7FH>
          </Link>
        </T11RContent>
      </LinkWrapper>
    </div>
  )
}

const ForgotPassword = () => {
  const [userEmail, setUserEmail] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const [formMessage, setFormError] = useState('')

  const validateEmail = async (email) => {
    try {
      const response = await axiosInstance.get(`api/users`)
      const users = response.data

      const userEmailExists = users.map((user) => user.email).includes(email)

      if (userEmailExists) return true

      return false
    } catch (error) {
      return false
    }
  }

  const handleEmailSubmit = async (event) => {
    event.preventDefault()

    const isValidEmail = await validateEmail(userEmail)
    if (!isValidEmail) {
      setFormError('Your account with this email does not exist')
      setEmailSent(false)
      return
    }

    axiosInstance
      .post('/api/auth/forgot-password', {
        email: userEmail,
      })
      .then(() => {
        setFormError('Password reset email sent successfully')
        setEmailSent(true)
      })
      .catch(() => {
        setFormError('An error occurred. Please try again.')
        setEmailSent(false)
      })
  }

  const handleInputChange = (e) => {
    setUserEmail(e.target.value)
    setFormError('')
  }

  return (
    <Container>
      <ComponentContainer>
        <ContentContainer>
          <Logo src={CoGsLogo} />
          <SpanTitle size="24px" align="center" margin="20px">
            Forget your password?
          </SpanTitle>
          <form
            onSubmit={handleEmailSubmit}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Input
              title="Email Address"
              inputType="email"
              value={userEmail}
              onChange={handleInputChange}
            />
            <PrimaryButton type="submit">Send</PrimaryButton>
            {formMessage && emailSent ? (
              <T11RContent color="green">{formMessage}</T11RContent>
            ) : (
              <T11RContent color="red">{formMessage}</T11RContent>
            )}
          </form>
          <NavigationComponent />
        </ContentContainer>
      </ComponentContainer>
    </Container>
  )
}

export default ForgotPassword

// const Container = styled.div`
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   align-items: flex-start;
//   width: 80%;
//   max-width: 800px;
//   max-height: 700px;
//   margin: 0 auto;

//   @media (max-width: ${size.tablet}) {
//     flex-direction: column;
//     align-items: center;
//   }
// `

// const ComponentContainer = styled.div`
//   background: #f0efeb;
//   height: auto;
//   box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
//   padding: 20px;
//   margin: 30px auto;
//   width: 100%;
//   max-width: 800px;

//   @media (max-width: ${size.tablet}) {
//     padding: 10px;
//     margin: 15px auto;
//     max-width: 100%;
//   }
// `

// const ContentContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: evenly;
// `

const Logo = styled.img`
  max-width: 250px;
  height: auto;
  margin: 20px;

  @media (max-width: ${size.tablet}) {
    max-width: 150px;
    margin: 10px;
  }
`

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px 0 0 0;
  text-align: center;
`

const Link = styled.a`
  color: #a0522e;
  text-decoration: none;
  margin: 0 5px;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`
