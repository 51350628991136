import styled from 'styled-components'
import { size } from '../../../assets/styles/mediaBreakpoints'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
`

export const ComponentContainer = styled.div`
  background: #f0efeb;
  width: 500px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  padding: 20px;
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ContentContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const SSOContainer = styled.div``

export const CheckBoxContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.8rem;
  margin: 10px;

  @media (max-width: ${size.tablet}) {
    font-size: 0.75rem;
    width: 200px;
  }
`

export const InputAndNavigationFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`
