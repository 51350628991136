import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { RxHamburgerMenu } from 'react-icons/rx'
import { useAuth } from '../../context/AuthContext'
import { PrimaryButton } from '../common/ui/Button'
import Logo from '../../assets/images/CoGs_logo.png'
import HamburgerMenu from './HamburgerMenu'

const Header = () => {
  const { isAuthenticated } = useAuth()
  const navigate = useNavigate()
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false)

  const toggleHamburgerMenu = () => {
    setIsHamburgerMenuOpen(!isHamburgerMenuOpen)
    document.body.style.overflow = !isHamburgerMenuOpen ? 'hidden' : 'auto'
  }

  useEffect(() => {
    // Cleanup function to reset overflow when the component unmounts
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  return (
    <HeaderContainer>
      <TopSection>
        <LogoAndLoginContainer>
          <LogoContainer>
            <HeaderLogo
              src={Logo}
              alt="Community of Guardians Logo"
              onClick={() => {
                navigate('/')
                if (isHamburgerMenuOpen) toggleHamburgerMenu()
              }}
            />
          </LogoContainer>
        </LogoAndLoginContainer>
        {isAuthenticated ? (
          <HamburgerMenuIconContainer>
            <HamburgerMenuIcon onClick={toggleHamburgerMenu} />
          </HamburgerMenuIconContainer>
        ) : (
          <HamburgerMenuIconContainer>
            <ButtonContainer>
              <SigninLink to="/signin">
                <PrimaryButton>Log In</PrimaryButton>
              </SigninLink>
            </ButtonContainer>
          </HamburgerMenuIconContainer>
        )}
      </TopSection>
      {isHamburgerMenuOpen && (
        <>
          <HamburgerMenu toggleHamburgerMenu={toggleHamburgerMenu} />
          <BackgroundBlur onClick={toggleHamburgerMenu} />
        </>
      )}
      <ContentContainer isBlurred={isHamburgerMenuOpen}>
        {/* Place your absolute positioned components here */}
      </ContentContainer>
    </HeaderContainer>
  )
}

export default Header

const HeaderLogo = styled.img`
  width: 200px;
  height: auto;
  max-height: 100px;
  cursor: pointer;
`

const HamburgerMenuIcon = styled(RxHamburgerMenu)`
  color: #2f632c;
  font-size: 30px;
  cursor: pointer;
`

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const LogoAndLoginContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`

const SigninLink = styled(Link)`
  text-decoration: none;
`

const LogoContainer = styled.div`
  display: flex;
  margin: 5px;
  margin-left: 10%;
  max-height: 100px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: auto;
`

const HamburgerMenuIconContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: 5%;
`

const HeaderContainer = styled.header`
  background-color: #f3fadc;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 85px;
`

const BackgroundBlur = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 1;
  cursor: pointer;
`

const ContentContainer = styled.div`
  ${({ isBlurred }) =>
    isBlurred &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(5px);
      z-index: 0;
    `}
`
