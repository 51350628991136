import React from 'react'
import styled from 'styled-components'
import DropdownMenu from '../../components/common/form/Dropdown'
import ResearchPreview from '../private/academic/ResearchPreview'
import { P, SpanKeyword } from '../../components/common/ui/Typography'
import IconComponent from '../../components/common/ui/Icon'
import { size } from '../../assets/styles/mediaBreakpoints'
import {
  useBreadcrumb,
  BreadcrumbItem,
} from '../../components/common/form/Address'

const StudentResearchRepository = () => {
  const items = useBreadcrumb()
  return (
    <ResearchContainer>
      <HeaderContainer>
        <AddressContainer>
          {items.map((item, index) => (
            <BreadcrumbItem
              key={item.name}
              name={item.name}
              path={item.path}
              isLast={index === items.length - 1}
            />
          ))}
        </AddressContainer>
        <TitleContainer>Student Research Repository</TitleContainer>
        <SearchContainer>
          <input type="search" placeholder="Search here ..." />
          <IconContainer>
            <IconComponent
              onClick={() => {}}
              iconName="ic:baseline-search"
              color="#8A8A8A"
              sizeIcon={24}
              style={{ margin: '0 5px 0 0' }}
              cursor="pointer"
            />
            <IconComponent
              onClick={() => {}}
              iconName="lets-icons:filter"
              color="#8A8A8A"
              sizeIcon={24}
              style={{ margin: '0 5px 0 0' }}
              cursor="pointer"
            />
          </IconContainer>
        </SearchContainer>
        <InfoContainer>
          <P>Latest article: </P>
        </InfoContainer>
      </HeaderContainer>
      <DropdownContainer>
        <SpanKeyword color="#9D9D9D">Subscribed SDGs</SpanKeyword>
        <SpanKeyword color="#9D9D9D">All SDGs</SpanKeyword>
        <DropdownMenu
          options={['Newest', 'Active', 'Popular']}
          placeholder="Sort by: Newest"
        />
      </DropdownContainer>
      <ResearchPreview
        author={{
          name: 'John Doe',
        }}
        date="2022-09-01"
        title="Smallholder Farmer Resilience: a Multi-year Multidimensional Study in the Dominican Republic and Haiti"
        abstract="Our program has demonstrated an ability to reduce poverty by two-thirds in participating communities meeting one of the UN Sustainable Development Goals. Economic improvements have not only been seen among direct participants but by other members of the communities where Plant With Purpose works, showing that our model is an effective means for widespread poverty reduction."
      />
      <ResearchPreview
        author={{
          name: 'John Doe',
        }}
        date="2022-09-01"
        title="The impact of climate change on the economy"
        abstract="This research paper explores the impact of climate change on the economy and provides recommendations on how to mitigate the effects."
      />
      <ResearchPreview
        author={{
          name: 'John Doe',
        }}
        date="2022-09-01"
        title="The impact of climate change on the economy"
        abstract="This research paper explores the impact of climate change on the economy and provides recommendations on how to mitigate the effects."
      />
      <ResearchPreview
        author={{
          name: 'John Doe',
        }}
        date="2022-09-01"
        title="The impact of climate change on the economy"
        abstract="This research paper explores the impact of climate change on the economy and provides recommendations on how to mitigate the effects."
      />
      <ResearchPreview
        author={{
          name: 'John Doe',
        }}
        date="2022-09-01"
        title="The impact of climate change on the economy"
        abstract="This research paper explores the impact of climate change on the economy and provides recommendations on how to mitigate the effects."
      />
    </ResearchContainer>
  )
}

export default StudentResearchRepository

const ResearchContainer = styled.div`
  margin-bottom: 50px;
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: column;
  margin-bottom: 30px;
`

const AddressContainer = styled.div`
  display: flex;
  justify-content: center;
`

const TitleContainer = styled.div`
  display: flex;
  margin: 0;
  justify-content: center;
  font-weight: 600;
  font-size: 48px;
  color: #2f632c;
  @media (max-width: ${size.desktop}) and (min-width: ${size.tablet}) {
    font-size: 32px;
  }
  @media (max-width: ${size.tablet}) and (min-width: ${size.mobile}) {
    font-size: 28px;
  }
  @media (max-width: ${size.mobile}) {
    font-size: 20px;
  }
`

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 600px;

  input[type='search'] {
    width: 100%;
    padding: 8px 40px 8px 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
  }
  @media (max-width: ${size.desktop}) and (min-width: ${size.tablet}) {
    max-width: 400px;
    input[type='search'] {
      font-size: 16px;
      padding: 8px 32px 8px 10px;
    }
  }
  @media (max-width: ${size.tablet}) and (min-width: ${size.mobile}) {
    max-width: 300px;
    input[type='search'] {
      font-size: 14px;
      padding: 8px 28px 8px 10px;
    }
  }
  @media (max-width: ${size.mobile}) {
    max-width: 70%;
    input[type='search'] {
      font-size: 12px;
      padding: 8px 24px 8px 10px;
    }
  }
`

const IconContainer = styled.div`
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
`

const InfoContainer = styled.div`
  display: flex;
  margin: auto;
  background-color: #f3fadc;
  width: 85%;
  height: 43px;
  align-items: center;

  P {
    padding-left: 20px;
  }
`

const DropdownContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10%;
  gap: 2%;
  font-size: 15px;
  margin-bottom: 20px;
  @media (max-width: ${size.desktop}) and (min-width: ${size.tablet}) {
    font-size: 18;
  }
  @media (max-width: ${size.tablet}) and (min-width: ${size.mobile}) {
    font-size: 14px;
  }
  @media (max-width: ${size.mobile}) {
    justify-content: center;
    font-size: 10px;
  }
`
