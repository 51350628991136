import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { P } from '../../components/common/ui/Typography'
import { PrimaryButton } from '../../components/common/ui/Button'

const SignoutAlert = ({ handleSignout, handleShowSignoutAlert }) => {
  return (
    <Overlay>
      <PopupInner>
        <P>Are you sure you want to sign out?</P>
        <ButtonContainer>
          <PrimaryButton color="grey" onClick={handleShowSignoutAlert}>
            No
          </PrimaryButton>
          <PrimaryButton onClick={handleSignout}>Yes</PrimaryButton>
        </ButtonContainer>
      </PopupInner>
    </Overlay>
  )
}

export default SignoutAlert

SignoutAlert.propTypes = {
  handleSignout: PropTypes.func.isRequired,
  handleShowSignoutAlert: PropTypes.func.isRequired,
}

// Styled components

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`

const PopupInner = styled.div`
  position: relative;
  background-color: #f3fadc;
  border-radius: 5px;
  padding: 20px;
  width: 300px;
  height: auto;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`
