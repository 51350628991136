import React from 'react'
import styled from 'styled-components'
import { device, size } from '../../assets/styles/mediaBreakpoints'
import Input from '../common/form/Input'
import IconComponent from '../common/ui/Icon'
import { T4Content, T5Content } from '../common/ui/Typography'

const AboutItem = () => (
  <FooterSection>
    <T4Content>About</T4Content>
    <List>
      <ListItem>
        <Link href="#!">Help Centre</Link>
      </ListItem>
      <ListItem>
        <Link href="#!">Site Map</Link>
      </ListItem>
    </List>
  </FooterSection>
)

const LegalItem = () => (
  <FooterSection>
    <T4Content>Legal</T4Content>
    <List>
      <ListItem>
        <Link href="#!">Contact Us</Link>
      </ListItem>
      <ListItem>
        <Link href="#!">Privacy Policy</Link>
      </ListItem>
      <ListItem>
        <Link href="/terms">Terms & Conditions</Link>
      </ListItem>
    </List>
  </FooterSection>
)

const FooterIcons = () => (
  <SocialIcons>
    <Link href="#!">
      <IconComponent iconName="mdi:twitter" color="#B64A07" sizeIcon={24} />
    </Link>
    <Link href="#!">
      <IconComponent
        iconName="akar-icons:facebook-fill"
        color="#B64A07"
        sizeIcon={24}
      />
    </Link>
    <Link href="#!">
      <IconComponent
        iconName="entypo-social:linkedin-with-circle"
        color="#B64A07"
        sizeIcon={24}
      />
    </Link>
    <Link href="#!">
      <IconComponent
        iconName="entypo-social:youtube-with-circle"
        color="#B64A07"
        sizeIcon={24}
      />
    </Link>
  </SocialIcons>
)

// Footer component
const Footer = () => (
  <FooterContainer>
    <FooterContent>
      <AboutItem />
      <LegalItem />
      <FooterSection>&nbsp;</FooterSection>
      <FooterSection>
        <T4Content margin="0 0 -5px 0">
          Let us know of your valuable feedback
        </T4Content>
        <FeedbackForm>
          <Input title="Email Address" inputType="email" footer />
        </FeedbackForm>
        <FooterIcons />
      </FooterSection>
    </FooterContent>
    <FooterBottom>
      <T5Content color="#F0EFEB">
        ©{new Date().getFullYear()} Community of Guardians
      </T5Content>
    </FooterBottom>
  </FooterContainer>
)

export default Footer
const FooterContainer = styled.div`
  background-color: #f3fadc;
  color: #8a8a8a;
  bottom: 0;
`

const FooterContent = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 10px;

  @media (${device.desktop}) {
    padding: 20px;
    gap: 20px;
  }
`

const FooterSection = styled.div`
  flex: 1;
  margin-left: 20px;

  &:first-child,
  &:nth-child(2) {
    flex: 0 1 auto;
  }

  &:last-child {
    flex: 2 1 auto;
  }
`

const List = styled.ul`
  list-style: none;
  padding: 0;
  @media (max-width: ${size.tablet}) {
    margin-top: 8px;
  }
`

const ListItem = styled.li`
  margin: 0 0 5px 0;
  font-size: 15px;
  font-weight: 600;
  @media (${device.desktop}) {
    font-size: 15px;
    line-height: 25px;
  }
`

const Link = styled.a`
  text-decoration: none;
  color: #8a8a8a;
`

const FeedbackForm = styled.form`
  display: flex;
  position: relative;
  max-width: 600px;
`

const SocialIcons = styled.div`
  display: flex;
  flex-direction: row;

  a {
    text-decoration: none;
    margin: -15px 0px -20px -5px;
  }

  @media (${device.mobile}) {
    a {
      text-decoration: none;
      margin: -15px -8px -20px -5px;
    }
  }

  @media (${device.desktop}) {
    a {
      text-decoration: none;
      margin: -15px -5px -20px -5px;
    }
  }
`

const FooterBottom = styled.div`
  background-color: #2f632c;
  text-align: center;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 0 0;
  @media (${device.desktop}) {
    height: 50px;
  }
`
