import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import IconComponent from '../../components/common/ui/Icon'
import { T1Content, T11RContent } from '../../components/common/ui/Typography'
import CommentComposer from '../../pages/private/HomeFeed/PostComposer/CommentComposer'
import { useAuth } from '../../context/AuthContext'
import axiosInstance from '../../config/AxiosInstance'
import ProfileImage from '../../assets/images/ProfileImage.png'

const Post = ({ data, depth = 0, onDeletePost, onUpdatePost }) => {
  const [showReplyForm, setShowReplyForm] = useState(false)
  const [replies, setReplies] = useState([])
  const [isLiked, setIsLiked] = useState(false)
  const [likes, setLikes] = useState(parseInt(data.likes, 10))
  const [isEditing, setIsEditing] = useState(false)
  const [editedContent, setEditedContent] = useState('')
  const [isDeleted, setIsDeleted] = useState(false)
  const { userId } = useAuth()
  const [postUserId, setPostUserId] = useState(null)
  const [description, setDescription] = useState(
    depth === 0
      ? data.description
      : data.attributes?.Text || 'No content available',
  )
  const UserName =
    depth === 0
      ? data.userName
      : data.attributes?.Userid?.data?.attributes?.username || 'Anonymous'

  useEffect(() => {
    if (data.attributes) {
      setLikes(parseInt(data.attributes.Likes, 10) || 0)
      setEditedContent(data.attributes.Text || '')
    }
  }, [data])

  useEffect(() => {
    const fetchPostWithReplies = async () => {
      try {
        const endpoint =
          depth === 0
            ? `/api/posts/${data.id}?populate[0]=Userid,LikedUsers,Replies&populate[1]=Replies.Userid`
            : `/api/replies/${data.id}?populate[0]=Userid,LikedUsers,Replies&populate[1]=Replies.Userid`

        const response = await axiosInstance.get(endpoint)

        const responseData = response.data.data
        if (responseData && responseData.attributes) {
          const postReplies = responseData.attributes.Replies?.data || []
          const likedUsers =
            responseData.attributes.LikedUsers?.data.map((user) => user.id) ||
            []
          setReplies(postReplies)
          setLikes(responseData.attributes.Likes || 0)

          const hasLiked = likedUsers.includes(userId)
          setIsLiked(hasLiked)
          if (depth === 0) {
            setPostUserId(responseData.attributes.Userid?.data?.id || null)
          }
        } else {
          // console.error('Unexpected response structure:', response.data)
        }
      } catch (error) {
        // console.error('Error fetching replies:', error)
      }
    }

    fetchPostWithReplies()
  }, [data.id, depth, userId])

  const handleReplyButtonClick = () => {
    setShowReplyForm(!showReplyForm)
  }

  const handleReplyAdded = (newReply) => {
    const userName =
      newReply.attributes.Userid?.data?.attributes.username || 'Anonymous'
    setReplies((prevReplies) => [
      ...prevReplies,
      {
        ...newReply,
        attributes: {
          ...newReply.attributes,
          Userid: {
            data: {
              attributes: {
                username: userName,
              },
            },
          },
        },
      },
    ])
  }

  const formatDate = (dateString) => {
    if (!dateString) return 'Unknown time'

    const date = new Date(dateString)
    if (Number.isNaN(date.getTime())) return 'Invalid Date'

    const now = new Date()
    const differenceInSeconds = Math.floor((now - date) / 1000)

    if (differenceInSeconds < 60) {
      return `${differenceInSeconds} seconds ago`
    }
    if (differenceInSeconds < 3600) {
      const minutes = Math.floor(differenceInSeconds / 60)
      return `${minutes} minutes ago`
    }
    if (differenceInSeconds < 43200) {
      const hours = Math.floor(differenceInSeconds / 3600)
      return `${hours} hours ago`
    }
    return date.toLocaleDateString()
  }

  const handleLike = async () => {
    const liked = !isLiked
    const currentLikes = parseInt(likes, 10)
    const updatedLikes = liked
      ? currentLikes + 1
      : Math.max(0, currentLikes - 1)

    setIsLiked(liked)
    setLikes(updatedLikes)

    // Determine whether the item is a post or a reply
    const isPost = depth === 0
    const endpoint = isPost
      ? `/api/posts/${data.id}?populate=LikedUsers`
      : `/api/replies/${data.id}?populate=LikedUsers`

    try {
      // Fetch current list of liked users
      const postDataResponse = await axiosInstance.get(endpoint)
      const currentPostData = postDataResponse.data.data

      const currentLikedUsers = currentPostData.attributes.LikedUsers.data.map(
        (user) => user.id,
      )

      let relationUpdate = {}

      // Update the list based on whether the post is being liked or unliked
      if (liked && !currentLikedUsers.includes(userId)) {
        relationUpdate = { connect: [{ id: userId }] }
      } else if (!liked) {
        relationUpdate = { disconnect: [{ id: userId }] }
      }

      setIsLiked(liked)
      setLikes(updatedLikes)

      if (Object.keys(relationUpdate).length > 0) {
        const updateResponse = await axiosInstance.put(
          endpoint.replace('?populate=LikedUsers', ''),
          {
            data: {
              Likes: updatedLikes,
              LikedUsers: relationUpdate,
            },
          },
        )
        // Check the response to confirm like state and count
        if (updateResponse.status === 200) {
          setIsLiked(liked)
          setLikes(updatedLikes)
        } else {
          throw new Error('Failed to update likes correctly.')
        }
      }
    } catch (error) {
      // console.error('Error updating likes:', error)
      setIsLiked(!isLiked)
      setLikes(liked ? currentLikes - 1 : currentLikes + 1)
    }
  }

  const handleDelete = async () => {
    try {
      if (depth === 0) {
        await axiosInstance.delete(`/api/posts/${data.id}`)
      } else {
        await axiosInstance.delete(`/api/replies/${data.id}`)
      }
      setIsDeleted(true)
      onDeletePost(data.id)
    } catch (error) {
      // console.error('Error deleting post/reply:', error)
    }
  }

  if (isDeleted) {
    return null
  }

  const handleEdit = () => {
    setIsEditing(true)
    setEditedContent(description)
  }

  const handleUpdate = async () => {
    try {
      const updatedData = {
        data: {
          Text: editedContent,
        },
      }

      if (depth === 0) {
        await axiosInstance.put(`/api/posts/${data.id}`, updatedData)
        setDescription(editedContent)
        onUpdatePost(data.id, editedContent)
      } else {
        await axiosInstance.put(`/api/replies/${data.id}`, updatedData)
        setDescription(editedContent)
        onUpdatePost(data.id, editedContent)
      }

      setIsEditing(false)
    } catch (error) {
      // console.error('Error updating post/reply:', error)
    }
  }

  const handleMediaError = (e) => {
    e.target.onerror = null // Prevents looping
    e.target.src = 'https://source.unsplash.com/random/50x50' // Placeholder image URL
    if (e.target.tagName === 'VIDEO') {
      e.target.poster = 'https://source.unsplash.com/random/50x50' // Placeholder for videos
    }
  }

  const renderMedia = (mediaUrls) => {
    if (!mediaUrls || mediaUrls.length === 0) {
      return null // Return null if no media is present
    }

    return mediaUrls.map((mediaUrl) => {
      const isVideo = mediaUrl.endsWith('.mp4' || '.avi') // Check if the URL ends with '.mp4'
      if (isVideo) {
        return (
          <Video
            key={mediaUrl}
            src={mediaUrl}
            controls
            onError={handleMediaError}
          >
            Your browser does not support the video tag.
          </Video>
        )
      }
      // Direct return for images, as no `else` is necessary.
      return (
        <Image
          key={mediaUrl}
          src={mediaUrl}
          alt="Content media"
          loading="lazy"
          onError={handleMediaError}
        />
      )
    })
  }

  const renderReplies = () => {
    return replies.map((reply) => {
      const userName =
        reply.attributes.Userid?.data?.attributes.username || 'Anonymous'
      const formattedTime = formatDate(reply.attributes.createdAt)
      return (
        <Post
          key={reply.id}
          data={{
            ...reply,
            attributes: {
              ...reply.attributes,
              userName,
              timeAgo: formattedTime,
              description: reply.attributes.Text || '',
              likes: reply.attributes.Likes || 0,
            },
            parent_reply: reply.attributes.parent_reply
              ? reply.attributes.parent_reply.data
              : null,
          }}
          depth={depth + 1}
          onDeletePost={(id) => {
            setReplies((prevReplies) => prevReplies.filter((r) => r.id !== id))
          }}
          onUpdatePost={(id, content) => {
            setReplies((prevReplies) =>
              prevReplies.map((r) =>
                r.id === id
                  ? { ...r, attributes: { ...r.attributes, Text: content } }
                  : r,
              ),
            )
          }}
        />
      )
    })
  }

  const formattedTimeAgo = formatDate(data.attributes?.createdAt)

  return (
    <PostWrapper depth={depth}>
      <ArticleContainer>
        <PostContent>
          <Header>
            <PostInfoContainer>
              <UserAvatarContainer>
                <UserAvatar
                  src={data.attributes?.userAvatar || ProfileImage}
                  alt={`Avatar for ${data.attributes?.userName}`}
                />
              </UserAvatarContainer>
              <T1Content color="0a0a0a">{UserName}</T1Content>
            </PostInfoContainer>
            <TimeAndIcons>
              {depth === 0 ? (
                <TimeAgo>{data.timeAgo}</TimeAgo>
              ) : (
                <TimeAgo>{formattedTimeAgo}</TimeAgo>
              )}

              <Icons>
                <IconWrapper onClick={() => {}} aria-label="Share">
                  <IconComponent
                    iconName="mdi:ios-share"
                    color="#C4c4c4"
                    sizeIcon={20}
                  />
                </IconWrapper>
                <IconWrapper onClick={() => {}} aria-label="Circle">
                  <IconComponent
                    iconName="mdi:minus-circle-outline"
                    color="#C4c4c4"
                    sizeIcon={20}
                  />
                </IconWrapper>
                {(depth === 0
                  ? userId === postUserId
                  : userId === data.attributes?.Userid?.data?.id) && (
                  <>
                    <IconWrapper onClick={handleDelete} aria-label="Delete">
                      <IconComponent
                        iconName="mdi:delete"
                        color="#C4c4c4"
                        sizeIcon={20}
                      />
                    </IconWrapper>
                    <IconWrapper onClick={handleEdit} aria-label="Edit">
                      <IconComponent
                        iconName="mdi:pencil"
                        color="#C4c4c4"
                        sizeIcon={20}
                      />
                    </IconWrapper>
                  </>
                )}
              </Icons>
            </TimeAndIcons>
          </Header>
          {isEditing ? (
            <>
              <EditTextArea
                value={editedContent}
                onChange={(e) => setEditedContent(e.target.value)}
              />
              <SaveButton onClick={handleUpdate}>Save</SaveButton>
            </>
          ) : (
            <>
              <DescContainer>{description}</DescContainer>
              {renderMedia(data.images)}
            </>
          )}
          <Interactions>
            <InteractionButton onClick={handleLike}>
              {likes} Like
              <IconComponent
                iconName={isLiked ? 'ph:heart-fill' : 'ph:heart'}
                color={isLiked ? 'red' : '#C4c4c4'}
                sizeIcon={25}
              />
            </InteractionButton>
            <InteractionButton onClick={handleReplyButtonClick}>
              {replies.length} Replies
              <IconComponent
                iconName="iconamoon:comment-thin"
                color="#C4c4c4"
                sizeIcon={25}
              />
            </InteractionButton>
          </Interactions>
          {showReplyForm && (
            <CommentComposer
              postId={depth === 0 ? data.id : data.parent_reply?.id}
              parentId={depth === 0 ? null : data.id}
              onReplyAdded={handleReplyAdded}
            />
          )}
          {renderReplies()}
        </PostContent>
      </ArticleContainer>
    </PostWrapper>
  )
}

Post.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    userName: PropTypes.string,
    description: PropTypes.string.isRequired,
    timeAgo: PropTypes.string,
    likes: PropTypes.number.isRequired,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        url: PropTypes.string.isRequired,
      }),
    ),
    attributes: PropTypes.shape({
      userName: PropTypes.string,
      userAvatar: PropTypes.string,
      createdAt: PropTypes.string,
      Text: PropTypes.string,
      Likes: PropTypes.number,
      Userid: PropTypes.shape({
        data: PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          attributes: PropTypes.shape({
            username: PropTypes.string,
          }),
        }),
      }),
      Replies: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
              .isRequired,
            attributes: PropTypes.shape({
              Text: PropTypes.string,
              createdAt: PropTypes.string,
              Likes: PropTypes.number,
              Userid: PropTypes.shape({
                data: PropTypes.shape({
                  attributes: PropTypes.shape({
                    username: PropTypes.string,
                  }),
                }),
              }),
            }),
          }),
        ),
      }),
    }),
    parent_reply: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
  depth: PropTypes.number,
  onDeletePost: PropTypes.func,
  onUpdatePost: PropTypes.func,
}

Post.defaultProps = {
  depth: 0,
  onDeletePost: () => {},
  onUpdatePost: () => {},
}

export default Post

const PostWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: visible;
  width: 100%;
  margin-left: ${({ depth }) => depth * 20}px;
`

const PostInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const UserAvatarContainer = styled.aside`
  padding: 0 20px 0 10px;
`

const UserAvatar = styled.img`
  width: 50x;
  height: 50px;
  border-radius: 50%;
`
const Video = styled.video`
  width: 50%; // Adjust based on your design
  height: auto;
  border-radius: 10px;
  margin: 10px 10px 0 0;
`

const ArticleContainer = styled.article`
  display: flex;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  background-color: #f0efeb;
`

const PostContent = styled.section`
  flex: 1;
  padding: 10px;
`

const Header = styled.header`
  margin-bottom: 15px;
`

const DescContainer = styled(T11RContent)`
  margin: 10px 0 0 0;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
  min-width: 0;
`

const TimeAndIcons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  margin-top: -10px;
`

const TimeAgo = styled.time`
  font-size: 0.8rem;
  color: #777;
  display: flex;
  align-items: center;
`

const IconWrapper = styled.button`
  all: unset;
  cursor: pointer;
  display: block;
  margin-right: -5px;
`

const Icons = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Interactions = styled.footer`
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
`

const InteractionButton = styled.button`
  margin-right: 15px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #c4c4c4;

  &:last-child {
    margin-right: 0;
  }
`

const Image = styled.img`
  width: 20%;
  margin: 10px 10px 0 0;
  border-radius: 10px;
`

const EditTextArea = styled.textarea`
  width: 100%;
  height: 100px;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
`

const SaveButton = styled.button`
  background-color: #2f632c;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`
