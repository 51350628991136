import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ResearchPreview from '../../../pages/private/academic/ResearchPreview'
import ProfileImage from '../../../assets/images/ProfileImage.png'

// Mock function to simulate fetching data
const fetchArticles = async (count = 5) => {
  // This should be replaced with an actual fetch request to your backend
  // For demonstration, we'll return an array of objects
  return new Array(count).fill(null).map((_, index) => ({
    id: index,
    author: {
      name: `Author ${index}`,
      image: ProfileImage, // Placeholder image
    },
    date: `2024-02-${10 + index}`,
    title: `Article Title ${index}`,
    abstract: `This is an abstract for article ${index}. It contains a brief overview of the article's content.`,
    keywords: ['science', 'research', 'innovation'],
    imageUrl: 'https://placekitten.com/200/300', // Placeholder image
    readTime: `${5 + index} min read`,
  }))
}

const ArticleListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ArticleList = ({ numberOfPreviews }) => {
  const [articles, setArticles] = useState([])

  useEffect(() => {
    const fetchAndSetArticles = async () => {
      const fetchedArticles = await fetchArticles(numberOfPreviews)
      setArticles(fetchedArticles)
    }

    fetchAndSetArticles()
  }, [numberOfPreviews])

  return (
    <ArticleListWrapper>
      {articles.map((article) => (
        <ResearchPreview
          key={article.id}
          author={article.author}
          date={article.date}
          title={article.title}
          abstract={article.abstract}
          keywords={article.keywords}
          imageUrl={article.imageUrl}
          readTime={article.readTime}
        />
      ))}
    </ArticleListWrapper>
  )
}

ArticleList.propTypes = {
  numberOfPreviews: PropTypes.number,
}

ArticleList.defaultProps = {
  numberOfPreviews: 4, // Default number of previews to show
}

export default ArticleList
