import styled from 'styled-components'
import { size } from '../../../assets/styles/mediaBreakpoints'

export const Separator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px;
`

export const Line = styled.div`
  height: 1px;
  background: grey;
  width: 110px;

  @media (max-width: ${size.tablet}) {
    width: 90px;
  }
`
