import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, useRoutes } from 'react-router-dom'
import Home from './pages/public/Home'
import About from './pages/public/About'
import Terms from './pages/public/Terms'
import Membership from './pages/public/Membership'
import SDG from './pages/private/sdgs/SDG'
import HomeFeed from './pages/private/HomeFeed/HomeFeed'
import SignIn from './features/popup/SignIn'
import SignUp from './features/popup/SignUp'
import ForgotPassword from './features/popup/ForgotPassword'
import Profile from './pages/private/profile/Profile'
import { useAuth } from './context/AuthContext'
import Layout from './components/layout/Layout'
import Subscription from './pages/private/sdgs/Subscription'
import Testing from './pages/public/Testing'
import StudentResearchRepository from './pages/public/StudentResearchRepository'
import ChangePasswordConfirmation from './pages/auth/resetPassword/ChangePasswordConfirmation'
import Research from './pages/public/Research'

export default function AppRouter() {
  const { isAuthenticated } = useAuth()

  return useRoutes([
    // Layout wrapper for common header and footer
    // This is the main router setup for our application.
    // Here, we define a route with the Layout component as its element.
    // The child routes of this path will be rendered inside the Layout, in place of the <Outlet />.
    { path: '/testing', element: <Testing /> },
    {
      path: '/',
      element: <Layout />,
      children: [
        { path: '/', element: <Home /> },
        {
          path: 'homefeed',
          element: (
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <HomeFeed />
            </ProtectedRoute>
          ),
        },
        {
          path: 'subscription',
          element: (
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Subscription />{' '}
            </ProtectedRoute>
          ),
        },
        {
          path: 'sdg/:id',
          element: (
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <SDG />
            </ProtectedRoute>
          ),
        },
        { path: 'terms', element: <Terms /> },
        { path: 'membership', element: <Membership /> },
        {
          path: 'profile',
          element: (
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Profile />
            </ProtectedRoute>
          ),
        },
        { path: 'about', element: <About /> },
        {
          path: 'research',
          element: <StudentResearchRepository />,
        },
        {
          path: 'research/:id',
          element: (
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Research />
            </ProtectedRoute>
          ),
        },
      ],
    },
    { path: '/signup', element: <SignUp /> },
    { path: '/signin', element: <SignIn /> },
    { path: '/forgot-password', element: <ForgotPassword /> },
    { path: '/reset-password', element: <ChangePasswordConfirmation /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ])
}

const ProtectedRoute = ({ children, isAuthenticated }) => {
  if (!isAuthenticated) {
    return <Navigate to="/signin" replace />
  }
  return children
}

ProtectedRoute.defaultProps = {
  isAuthenticated: false,
}

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  isAuthenticated: PropTypes.bool,
}
