import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { IoIosClose, IoIosArrowForward, IoIosArrowDown } from 'react-icons/io'
import { NavLink, useNavigate } from 'react-router-dom'
import { MdLogout } from 'react-icons/md'
import { useAuth } from '../../context/AuthContext'
import Logo from '../../assets/images/CoGs_logo.png'
import UserProfile from '../common/ui/UserProfile'
import SignoutAlert from '../../features/popup/SignoutAlert'

const MenuItemWithChildren = ({
  parentName,
  children,
  expandedItem,
  setExpandedItem,
  hoverSupported,
  handleToggleItem,
  toggleHamburgerMenu,
}) => {
  return (
    <ParentMenuItemWithChildren
      onClick={() => handleToggleItem(parentName)}
      onMouseEnter={hoverSupported ? () => handleToggleItem(parentName) : null}
      onMouseLeave={hoverSupported ? () => setExpandedItem(null) : null}
    >
      <ParentMenuItem className={expandedItem === parentName ? 'active' : ''}>
        {expandedItem === parentName ? (
          <IoIosArrowDown size={20} />
        ) : (
          <IoIosArrowForward size={20} />
        )}
        {window.location.pathname === children[0].path ? (
          <ChildMenuItem className="active">{parentName}</ChildMenuItem>
        ) : (
          <ParentMenuItemTitleWithoutChildren>
            {parentName}
          </ParentMenuItemTitleWithoutChildren>
        )}
      </ParentMenuItem>
      {expandedItem === parentName && (
        <ChildMenu>
          {children.map((child) => (
            <StyledNavLink
              to={child.path}
              key={child.name}
              onClick={toggleHamburgerMenu}
            >
              <ChildMenuItem>{child.name}</ChildMenuItem>
            </StyledNavLink>
          ))}
        </ChildMenu>
      )}
    </ParentMenuItemWithChildren>
  )
}

const ParentItemWithoutChildren = ({
  name,
  path,
  handleToggleItem,
  toggleHamburgerMenu,
}) => {
  return (
    <StyledNavLink
      to={path}
      onClick={() => {
        handleToggleItem(null)
        toggleHamburgerMenu()
      }}
    >
      {window.location.pathname === path ? (
        <ChildMenuItem className="active">{name}</ChildMenuItem>
      ) : (
        <ParentMenuItem>{name}</ParentMenuItem>
      )}
    </StyledNavLink>
  )
}

const HamburgerMenu = ({ toggleHamburgerMenu }) => {
  const [expandedItem, setExpandedItem] = useState(null)
  const [hoverSupported, setHoverSupported] = useState(false)
  const [showSignoutAlert, setShowSignoutAlert] = useState(false)
  const navigate = useNavigate()
  const { logout, userName, userEmail } = useAuth()

  useEffect(() => {
    const query = window.matchMedia('(hover: hover)')
    setHoverSupported(query.matches)
  }, [])

  const handleToggleItem = (item) => {
    setExpandedItem(expandedItem === item ? null : item)
  }

  const handleShowSignoutAlert = () => {
    setShowSignoutAlert(!showSignoutAlert)
  }

  const handleSignOut = () => {
    logout()
    navigate('/signin')
  }

  const menuItems = [
    { name: 'Home', path: '/homefeed' },
    { name: 'SDG Groups', path: '/subscription' },
    { name: 'Research', path: '/research' },
    {
      name: 'About',
      children: [
        { name: 'Membership', path: '/membership' },
        { name: 'Terms', path: '/terms' },
      ],
    },
  ]

  return (
    <Menu>
      <TopSection>
        <MenuLogo
          src={Logo}
          alt="Community of Guardians Logo"
          onClick={toggleHamburgerMenu}
        />
        <IoIosClose
          style={{ cursor: 'pointer' }}
          size={50}
          onClick={toggleHamburgerMenu}
        />
      </TopSection>
      <Separator />
      <ContentSection>
        {menuItems.map((item) => {
          if (item.children) {
            return (
              <MenuItemWithChildren
                key={item.name}
                parentName={item.name}
                expandedItem={expandedItem}
                setExpandedItem={setExpandedItem}
                hoverSupported={hoverSupported}
                handleToggleItem={handleToggleItem}
                toggleHamburgerMenu={toggleHamburgerMenu}
              >
                {item.children}
              </MenuItemWithChildren>
            )
          }
          return (
            <ParentItemWithoutChildren
              key={item.name}
              name={item.name}
              path={item.path}
              handleToggleItem={handleToggleItem}
              toggleHamburgerMenu={toggleHamburgerMenu}
            />
          )
        })}
      </ContentSection>
      <Separator />
      <BottomSection>
        <UserProfile
          email={userEmail}
          name={userName}
          disableTitle
          hamburgerMenu
        />
        <SignOutButton onClick={handleShowSignoutAlert} />
      </BottomSection>
      {showSignoutAlert && (
        <SignoutAlert
          handleSignout={handleSignOut}
          handleShowSignoutAlert={handleShowSignoutAlert}
        />
      )}
    </Menu>
  )
}

export default HamburgerMenu

HamburgerMenu.propTypes = {
  toggleHamburgerMenu: PropTypes.func.isRequired,
}

MenuItemWithChildren.propTypes = {
  parentName: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ).isRequired,
  expandedItem: PropTypes.string,
  setExpandedItem: PropTypes.func.isRequired,
  hoverSupported: PropTypes.bool.isRequired,
  handleToggleItem: PropTypes.func.isRequired,
  toggleHamburgerMenu: PropTypes.func.isRequired,
}

MenuItemWithChildren.defaultProps = {
  expandedItem: null,
}

ParentItemWithoutChildren.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  handleToggleItem: PropTypes.func.isRequired,
  toggleHamburgerMenu: PropTypes.func.isRequired,
}

const SignOutButton = styled(MdLogout)`
  font-size: 30px;
  cursor: pointer;
`

const ParentMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0;

  &.active {
    font-weight: bold; // Only the active link is bold
  }
`

const ParentMenuItemTitleWithoutChildren = styled.p`
  margin: 0;
  padding: 0px 10px;

  &:hover {
    font-weight: bold;
  }

  &.active {
    font-weight: bold; // Only the active link is bold
  }
`

const ParentMenuItemWithChildren = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 10px 20px;
  cursor: pointer;
  text-decoration: none;
  color: inherit;

  &:hover {
    font-weight: bold;
    background-color: #e3f9a6;
  }
`

const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding: 10px 20px;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  font-weight: normal;

  &:hover {
    font-weight: bold;
    background-color: #e3f9a6;
  }
`

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
`

const ContentSection = styled.div`
  height: 75%;
`

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`

const MenuLogo = styled.img`
  width: 150px;
  height: auto;
  max-height: 100px;
`

const Separator = styled.div`
  width: 90%;
  height: 1px;
  margin: 15px 0;
  background-color: #2f632c;
  align-self: center;
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;
  position: absolute;
  background-color: #f3fadc;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`

const ChildMenu = styled.div`
  display: flex;
  flex-direction: column;
  left: 100%;
  top: 0;
  background-color: transparent;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
`

const ChildMenuItem = styled.div`
  cursor: pointer;
  border-radius: 5px;

  &.active {
    font-weight: bold;
  }
`
