import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { H4Text, T6Content } from '../../components/common/ui/Typography'
import Hero from '../../components/common/ui/Hero'
import HomeImage1 from '../../assets/images/HomepageImages/community.png'
import HomeImage2 from '../../assets/images/HomepageImages/artic.png'
import HomeImage3 from '../../assets/images/HomepageImages/marinelife.png'
import HomeImage4 from '../../assets/images/HomepageImages/clean_energy.png'
import { size } from '../../assets/styles/mediaBreakpoints'

const Home = () => {
  const [currentImage, setCurrentImage] = useState(0)

  const images = [
    {
      imageUrl: HomeImage1,
    },
    {
      imageUrl: HomeImage2,
      fontColor: '#0a0a0a',
    },
    {
      imageUrl: HomeImage3,
    },
    {
      imageUrl: HomeImage4,
    },
  ]

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length)
    }, 4000)

    return () => clearInterval(intervalId)
  }, [images.length])

  const image = images[currentImage]

  return (
    <>
      <Hero
        title="Advocate for issues you are passionate about."
        buttonText1="WHAT'S MY SDG NUMBER?"
        buttonText2="I KNOW MY SDGs"
        buttonOnClick1={() => {}}
        imageUrl={image.imageUrl}
        fontColor={image.fontColor}
      />
      <Container>
        <br />
        <H4Text>Shaping a brighter tomorrow by SDGs</H4Text>
        <br />
        <T6Content>
          It’s no secret that our world is not in a good place. If we continue
          down this status-quo, we will only continue to harm our planet, and
          ultimately ourselves.
          <br /> <br />
          Did you know that there’s a blueprint for peace and prosperity for
          people and the planet that exists? It’s called the 2030 Agenda for
          Sustainable Development. This blueprint was adopted by all United
          Nations Member States in 2015.
          <br /> <br />
          Within that blueprint are 17 Sustainable Development Goals (SDG) –
          they are an urgent call to action for all countries in a global
          partnership.
          <br /> <br />
          SDGs recognize that everything is interconnected to each other and we
          must take action to address issues like ending poverty and other
          deprivations if we also want to improve health and education, reduce
          inequality and spur economic growth. All while also tackling climate
          change and preserving our forests and oceans. <br /> <br />
          Our Earth is the only home we have, regardless of who we are and where
          we live, we must action the SDGs to save our home and ourselves, for
          our present and our future generations.
        </T6Content>
        <br />
        <H4Text>Who we are</H4Text>
        <YouTubeVideoContainer>
          <iframe
            src="https://www.youtube.com/embed/9jEL3z4pOTg"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </YouTubeVideoContainer>
        <br />
      </Container>
    </>
  )
}

export default Home

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 50px 100px;

  @media (max-width: ${size.desktop}) {
    padding: 50px 80px;
  }

  @media (max-width: ${size.tablet}) {
    padding: 30px 40px;
  }
`

const YouTubeVideoContainer = styled.div`
  width: 100%;
  padding-top: 56.25%;
  align-items: center;
  justify-content: center;
  position: relative;
  iframe {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 90%;
  }

  @media (max-width: ${size.tablet}) {
    iframe {
      width: 100%;
      height: 100%;
    }
  }
`
