import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FcGoogle } from 'react-icons/fc'
import { Icon } from '@iconify/react'
// eslint-disable-next-line import/no-unresolved
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import CoGsLogo from '../../assets/images/CoGs_logo.png'
import Input from '../../components/common/form/Input'
import { INPUT_TYPES } from '../feed/utils/Constants'
import {
  SpanSmall,
  P,
  SpanTitle,
  SSOButtonText,
  SignInAndSignUpLinkText,
  NavigateBack,
} from '../../components/common/ui/Typography'
import {
  AuthenticationButton,
  SSOButton,
} from '../../components/common/ui/Button'
import {
  Container,
  ComponentContainer,
  ContentContainer,
  SSOContainer,
  CheckBoxContainer,
  InputAndNavigationFieldContainer,
} from '../../components/common/ui/Container'
import { Line, Separator } from '../../components/common/ui/Line'
import axiosInstance from '../../config/AxiosInstance'
import { useAuth } from '../../context/AuthContext'

const SSOComponent = () => {
  return (
    <>
      <SSOContainer>
        <SSOButton>
          <SSOButtonText>
            <SpanSmall size="0.8rem" color="#2F632C">
              Continue with Google
            </SpanSmall>
          </SSOButtonText>
          <FcGoogle style={{ fontSize: '20px' }} />
        </SSOButton>
        <SSOButton>
          <SSOButtonText>
            <SpanSmall size="0.8rem" color="#2F632C">
              Continue with Facebook
            </SpanSmall>
          </SSOButtonText>

          <Icon icon="logos:facebook" style={{ fontSize: '20px' }} />
        </SSOButton>
      </SSOContainer>
      <Separator>
        <Line />
        <SpanSmall margin="0 10px" size="10px">
          OR
        </SpanSmall>
        <Line />
      </Separator>
    </>
  )
}

const InputFieldsComponent = ({
  username,
  setUsername,
  email,
  setEmail,
  password,
  setPassword,
  verifyPassword,
  setVerifyPassword,
}) => {
  return (
    <InputAndNavigationFieldContainer>
      <Input
        title="User Name"
        inputType={INPUT_TYPES.text}
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <Input
        title="Email Address"
        inputType={INPUT_TYPES.email}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Input
        title="Create Your Password"
        inputType={INPUT_TYPES.password}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Input
        title="Verify Your Password"
        inputType={INPUT_TYPES.password}
        value={verifyPassword}
        onChange={(e) => setVerifyPassword(e.target.value)}
      />
    </InputAndNavigationFieldContainer>
  )
}

const CheckBoxComponent = ({
  isEmbassador,
  setIsEmbassador,
  agreedToTerms,
  setAgreedToTerms,
}) => {
  return (
    <>
      <CheckBoxContainer>
        <CheckBox
          id="embassador"
          checked={isEmbassador}
          onChange={(e) => setIsEmbassador(e.target.checked)}
          value="Become an Embassador"
          boxShape="circle"
        />
        <Label htmlFor="embassador">Become an Embassador</Label>
      </CheckBoxContainer>
      <CheckBoxContainer>
        <CheckBox
          id="terms"
          checked={agreedToTerms}
          onChange={(e) => setAgreedToTerms(e.target.checked)}
          value="Agreed to terms"
          boxShape="rectangle"
        />
        <Label htmlFor="terms">
          Agreed to{' '}
          <SignInAndSignUpLinkText
            href="https://www.linkedin.com/company/community-of-guardians/"
            rel="noreferrer noopener"
            target="_blank"
          >
            Membership Agreement
          </SignInAndSignUpLinkText>{' '}
          and{' '}
          <SignInAndSignUpLinkText
            href="https://www.linkedin.com/company/community-of-guardians/"
            rel="noreferrer noopener"
            target="_blank"
          >
            Terms of References
          </SignInAndSignUpLinkText>
        </Label>
      </CheckBoxContainer>
    </>
  )
}

const NavigationComponent = ({ handleSignUp }) => {
  return (
    <InputAndNavigationFieldContainer>
      <AuthenticationButton onClick={handleSignUp}>
        Get Started
      </AuthenticationButton>
      <NavigateBack href="/">
        <Icon icon="akar-icons:arrow-left" style={{ color: '#2f632c' }} />
        <P margin="10px" weight="600">
          Back
        </P>
      </NavigateBack>
      <AlreadyHaveAccount>
        <SpanSmall>
          Already have an account?&nbsp;
          <SignInAndSignUpLinkText href="/SignIn" rel="noreferrer noopener">
            Sign In
          </SignInAndSignUpLinkText>
        </SpanSmall>
      </AlreadyHaveAccount>
    </InputAndNavigationFieldContainer>
  )
}

const SignUp = ({ SSOtoggle }) => {
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [verifyPassword, setVerifyPassword] = useState('')
  const [isEmbassador, setIsEmbassador] = useState(false)
  const [agreedToTerms, setAgreedToTerms] = useState(false)
  const [formError, setFormError] = useState(null)

  const { login } = useAuth()
  const navigate = useNavigate()

  const handleSignUp = async (e) => {
    e.preventDefault()
    setFormError(null)

    if (password !== verifyPassword) {
      setFormError("Passwords don't match!")
      return
    }

    if (!agreedToTerms) {
      setFormError('Please agree to the terms!')
      return
    }

    try {
      // Check if email already exists

      const validateEmail = async (inputEmail) => {
        try {
          const response = await axiosInstance.get(`api/users`)
          const users = response.data

          const userEmailExists = users
            .map((user) => user.email)
            .includes(inputEmail)

          if (userEmailExists) return true

          return false
        } catch (error) {
          return false
        }
      }
      const emailExists = await validateEmail(email)
      if (emailExists) {
        setFormError('Email already exists!')
        return
      }

      const response = await axiosInstance.post(`api/auth/local/register`, {
        username,
        email,
        password,
      })
      if (response.status === 200) {
        login(response.data)
        navigate('/homefeed')
      }
    } catch (error) {
      // console.error(
      //   'Registration error:',
      //   error.response?.data || error.message,
      // )
      setFormError('Name or email already exists!')
    }
  }

  return (
    <Container>
      <ComponentContainer>
        <ContentContainer>
          <LogoImage src={CoGsLogo} />
          <SpanTitle size="24px" align="center" margin="20px">
            Let&apos;s Get Started!
          </SpanTitle>
          {SSOtoggle ? <SSOComponent /> : null}
          <InputFieldsComponent
            username={username}
            setUsername={setUsername}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            verifyPassword={verifyPassword}
            setVerifyPassword={setVerifyPassword}
          />
          {formError && (
            <P color="red" margin="10px">
              {formError}
            </P>
          )}
          <CheckBoxComponent
            isEmbassador={isEmbassador}
            setIsEmbassador={setIsEmbassador}
            agreedToTerms={agreedToTerms}
            setAgreedToTerms={setAgreedToTerms}
          />
          <NavigationComponent handleSignUp={handleSignUp} />
        </ContentContainer>
      </ComponentContainer>
    </Container>
  )
}

SignUp.propTypes = {
  SSOtoggle: PropTypes.bool.isRequired,
}

NavigationComponent.propTypes = {
  handleSignUp: PropTypes.func.isRequired,
}

InputFieldsComponent.propTypes = {
  username: PropTypes.string.isRequired,
  setUsername: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  verifyPassword: PropTypes.string.isRequired,
  setVerifyPassword: PropTypes.func.isRequired,
}

CheckBoxComponent.propTypes = {
  isEmbassador: PropTypes.bool.isRequired,
  setIsEmbassador: PropTypes.func.isRequired,
  agreedToTerms: PropTypes.bool.isRequired,
  setAgreedToTerms: PropTypes.func.isRequired,
}

export default SignUp

const LogoImage = styled.img`
  max-width: 200px;
  height: auto;
  margin: 20px;
`

const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: ${({ boxShape }) => (boxShape === 'circle' ? '50%' : '5px')};
  border: 1px solid #2f632c;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  cursor: pointer;
  position: relative;

  &:checked::after {
    content: '✔';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #2f632c;
    font-size: 14px;
  }
`

const Label = styled.label`
  margin: 0 0 0 8px;
  cursor: pointer;
`

const AlreadyHaveAccount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px;
`
