import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { PrimaryButton } from '../ui/Button'
import IconComponent from '../ui/Icon'
import { T4Content, T5Content, T7FH, Span } from '../ui/Typography'
import { device } from '../../../assets/styles/mediaBreakpoints'

const SDGCard = ({
  sdgImage,
  title,
  keyword1,
  keyword2,
  keyword3,
  keyword4,
  keyword5,
  lastActive,
  description,
  isSubscribed,
  onSubscribeClick,
  onJoinClick,
  membersImage,
  membersText,
}) => {
  const [subscribed, setSubscribed] = useState(isSubscribed)
  const keywordRef = useRef(null)

  const scrollAmount = 100

  const scrollLeft = () => {
    if (keywordRef && keywordRef.current) {
      keywordRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' })
    }
  }

  const scrollRight = () => {
    if (keywordRef && keywordRef.current) {
      keywordRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' })
    }
  }

  const timeSinceActive = (isoDate) => {
    const then = new Date(isoDate)
    const now = new Date()

    if (Number.isNaN(then.getTime())) {
      return 'Date is invalid'
    }

    const difference = now - then
    const daysAgo = Math.floor(difference / (1000 * 3600 * 24))

    if (daysAgo < 0) {
      return 'Date in the future'
    }
    if (daysAgo === 1) {
      return 'Active yesterday'
    }

    return daysAgo === 0 ? 'Active today' : `Active ${daysAgo} days ago`
  }

  const handleSubscribeClick = () => {
    setSubscribed(!subscribed)
    onSubscribeClick(subscribed)
  }

  useEffect(() => {
    setSubscribed(isSubscribed)
  }, [isSubscribed])

  return (
    <CardContainer>
      <SDGImage src={sdgImage} alt={`SDG ${title}`} />
      <SDGInfoContainer>
        <ScrollableContainer>
          <IconOnlyButton onClick={scrollLeft}>
            <IconComponent
              iconName="ic:round-arrow-left"
              color="#a0a0a0"
              sizeIcon="1em"
            />
          </IconOnlyButton>
          <KeywordsContainer ref={keywordRef}>
            <Tag href={`#/${keyword1}`}>#{keyword1}</Tag>
            <Tag href={`#/${keyword2}`}>#{keyword2}</Tag>
            <Tag href={`#/${keyword3}`}>#{keyword3}</Tag>
            <Tag href={`#/${keyword4}`}>#{keyword4}</Tag>
            <Tag href={`#/${keyword5}`}>#{keyword5}</Tag>
          </KeywordsContainer>
          <IconOnlyButton onClick={scrollRight}>
            <IconComponent
              iconName="ic:round-arrow-right"
              color="#a0a0a0"
              sizeIcon="1em"
            />
          </IconOnlyButton>
        </ScrollableContainer>
        <TitleContainer>
          <StyledT4Content>{title}</StyledT4Content>
        </TitleContainer>
        <LastActive>{timeSinceActive(lastActive)}</LastActive>
        <T5Content
          margin="0 0 32px 0"
          color="0a0a0a"
          flex="1"
          white-space="nowrap"
          overflow="hidden"
          text-overflow="ellipsis"
        >
          {description}
        </T5Content>
        <MembersContainer>
          <JoinGroupImage src={membersImage} alt="Join Members" />
          <Span margin="0 0 12px 16px" size="14px" color="#8A8A8A">
            {membersText}
          </Span>
        </MembersContainer>
      </SDGInfoContainer>
      <ActionContainer>
        <HeartIconContainer onClick={handleSubscribeClick}>
          <IconComponent
            iconName={subscribed ? 'fa:heart' : 'fa-regular:heart'}
            color={subscribed ? 'red' : '#2f632C'}
            sizeIcon="1em"
            cursor="pointer"
          />
        </HeartIconContainer>
        <JoinButton onClick={() => onJoinClick(subscribed)}>
          {subscribed ? 'Visit' : 'Join us'}
        </JoinButton>
      </ActionContainer>
    </CardContainer>
  )
}

SDGCard.propTypes = {
  sdgImage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  keyword1: PropTypes.string,
  keyword2: PropTypes.string,
  keyword3: PropTypes.string,
  keyword4: PropTypes.string,
  keyword5: PropTypes.string,
  lastActive: PropTypes.string,
  description: PropTypes.string,
  isSubscribed: PropTypes.bool,
  onSubscribeClick: PropTypes.func.isRequired,
  onJoinClick: PropTypes.func.isRequired,
  membersImage: PropTypes.string.isRequired,
  membersText: PropTypes.string.isRequired,
}

SDGCard.defaultProps = {
  description: '',
  isSubscribed: false,
  keyword1: '',
  keyword2: '',
  keyword3: '',
  keyword4: '',
  keyword5: '',
  lastActive: '',
}

export default SDGCard

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 5px;
  background-color: white;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;

  @media ${device.tablet} {
    padding: 2.5rem;
    max-width: 320px;
  }

  &:hover {
    box-shadow: 0 8px 26px rgba(0, 128, 50, 0.1);
  }
`

const SDGImage = styled.img`
  width: 100%;
`

const ScrollableContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin: 0 0 0 -15px;
  width: 110%;
`

const IconOnlyButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin: 0 -15px;

  &:hover {
    opacity: 0.8;
  }
`

const KeywordsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 50px;
  margin: 15px 0 0 0;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;

  /* Hide scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

const Tag = styled(T7FH).attrs({
  as: 'a',
})`
  margin: 0 20px 0 0;
  // width: 100%;
  white-space: nowrap;
  color: #b64a07;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline; // Adds underline on hover for better user experience
  }
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 50px;
  overflow: hidden;
`

const StyledT4Content = styled(T4Content)`
  margin: 0;
  line-height: 1.2;
  overflow: visible;
`

const LastActive = styled(T7FH)`
  color: #2f632c;
  padding: 4px 0;
`

const JoinGroupImage = styled.img`
  width: 100px;
  height: auto;
  margin: 0 0 5px 0;
`

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const HeartIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 1rem;
  background-color: #f3fadc; 
  padding: 0.5rem; 
  height: 25px; 
  width: 60px; 
  // Since this is mobile-first, no need for a mobile-specific media query here

  // Media query for larger screens 
  @media ${device.tablet} {
    padding: 0.5rem 0.8rem; 
`

const JoinButton = styled(PrimaryButton)`
  padding: 0.5rem 1.5rem;
  min-width: 200px;
  height: 40px;
  background-color: #2f632c;
  color: #f0efeb;
  border: none;
  border-radius: 1rem;
  font-weight: bold;
  cursor: pointer;
`

const MembersContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px 0 0 0;
`

const SDGInfoContainer = styled.div`
  padding: 1rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`
