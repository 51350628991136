import React from 'react'
import PropTypes from 'prop-types'
import ProfileMenu from '../../../components/common/unique/ProfileMenu'
import { useAuth } from '../../../context/AuthContext'

const Profile = ({ onMakePostClick, onAllPostsClick }) => {
  const { userName, userEmail } = useAuth()
  return (
    <ProfileMenu
      email={userEmail}
      name={userName}
      onAllPostsClick={onAllPostsClick}
      onMakePostClick={onMakePostClick}
    />
  )
}

Profile.propTypes = {
  onMakePostClick: PropTypes.func.isRequired,
  onAllPostsClick: PropTypes.func.isRequired,
}

export default Profile
