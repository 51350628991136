import React from 'react'
import PropTypes from 'prop-types'
import { Icon as IconifyIcon } from '@iconify/react'
import styled from 'styled-components'
import { size } from '../../../assets/styles/mediaBreakpoints'

const IconComponent = ({ iconName, color, sizeIcon, ...props }) => (
  <StyledIcon
    icon={iconName}
    color={color}
    width={sizeIcon}
    height={sizeIcon}
    onClick={() => ''}
    {...props}
  />
)

IconComponent.propTypes = {
  iconName: PropTypes.string.isRequired,
  color: PropTypes.string,
  sizeIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

IconComponent.defaultProps = {
  color: 'currentColor',
  sizeIcon: '1em',
}

export default IconComponent

const IconGroup = () => {
  return (
    <IconGroupContainer>
      <IconComponent
        onClick={() => {}}
        iconName="fa:search"
        color=""
        sizeIcon=""
        cursor="pointer"
      />
      <IconComponent
        onClick={() => {}}
        iconName="fa-solid:user-circle"
        color=""
        sizeIcon=""
        cursor="pointer"
      />
      <IconComponent
        onClick={() => {}}
        iconName="fa-regular:heart"
        color=""
        sizeIcon=""
        cursor="pointer"
      />
    </IconGroupContainer>
  )
}

export { IconGroup }

const IconGroupContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 5%;
`
const StyledIcon = styled(IconifyIcon)`
  display: flex;
  border-radius: 8px;
  margin: 10px;
  gap: calc(max(10px, min(5vw, 50px)));
  font-size: 30px;
  color: #2f632c;
  @media (max-width: ${size.mobile}) {
    font-size: 23px;
    // margin-right: 1%;
  }
  @media (max-width: ${size.tablet}) and (min-width: ${size.mobile}) {
    font-size: 25px;
  }
  @media (max-width: ${size.desktop}) and (min-width: ${size.tablet}) {
    font-size: 29px;
  }
`
