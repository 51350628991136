import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { NavLink, useNavigate } from 'react-router-dom'
import SmallHero from '../../../components/common/ui/SmallHero'
import SDGCard from '../../../components/common/unique/SDGCard'
import HeroImage1 from '../../../assets/images/SubscriptionMainImages/SmallHero.png'
import HeroImage2 from '../../../assets/images/SubscriptionMainImages/2.png'
import HeroImage3 from '../../../assets/images/SubscriptionMainImages/3.png'
import HeroImage4 from '../../../assets/images/SubscriptionMainImages/4.png'
import DropdownMenu from '../../../components/common/form/Dropdown'
import { H4Text } from '../../../components/common/ui/Typography'
import { size } from '../../../assets/styles/mediaBreakpoints'
import MembersImage from '../../../assets/images/Members.png'
import { useAuth } from '../../../context/AuthContext'
import axiosInstance from '../../../config/AxiosInstance'

const Subscription = () => {
  const [sdgs, setSdgs] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [currentDisplay, setCurrentDisplay] = useState(0)
  const [userSubscriptions, setUserSubscriptions] = useState([])
  const navigate = useNavigate()
  const { userId } = useAuth()

  // Handle subscription changes
  const handleSubscribe = async (sdgId, isSubscribed) => {
    const newSubscriptions = isSubscribed
      ? userSubscriptions.filter((id) => id !== sdgId)
      : [...userSubscriptions, sdgId]

    try {
      setUserSubscriptions(newSubscriptions)
      const response = await axiosInstance.put(`/api/users/${userId}`, {
        SDG_Subscription: newSubscriptions,
      })

      if (response.status !== 200) {
        throw new Error('Failed to update the subscription')
      }
    } catch (e) {
      // console.error('Subscription update failed', e)
      setUserSubscriptions(userSubscriptions)
    }
  }

  const handleJoinAndNavigate = (sdgId) => {
    const isSubscribed = userSubscriptions.includes(sdgId)

    if (!isSubscribed) {
      handleSubscribe(sdgId, isSubscribed)
    }
    navigate(`/sdg/${sdgId}`)
  }

  // Display data for rotating hero images
  const displays = [
    {
      sdgId: 13,
      imageUrl: HeroImage1,
      buttonText: 'JOIN US ON SDG 13',
      buttonAction: () => handleJoinAndNavigate(13),
      text: 'TAKING A STAND FOR CLIMATE ACTION',
    },
    {
      sdgId: 15,
      imageUrl: HeroImage2,
      buttonText: 'JOIN US ON SDG 15',
      buttonAction: () => handleJoinAndNavigate(15),
      text: 'THE WONDERS OF LIFE ON LAND',
      fontColor: '#F3FADC',
    },
    {
      sdgId: 12,
      imageUrl: HeroImage3,
      buttonText: 'JOIN US ON SDG 12',
      buttonAction: () => handleJoinAndNavigate(12),
      text: 'PIONEERING RESPONSIBLE CONSUMPTION AND PRODUCTION FOR A REGERATIVE WORLD',
    },
    {
      sdgId: 4,
      imageUrl: HeroImage4,
      buttonText: 'JOIN US ON SDG 4',
      buttonAction: () => handleJoinAndNavigate(4),
      text: 'UNLEASHING THE POTENTIAL OF QUALITY EDUCATION ',
      fontColor: '#F3FADC',
    },
  ]

  // Fetch SDGs independently of authentication status
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        // Using axiosInstance to make the HTTP request
        const response = await axiosInstance.get('/api/sdgs?populate=*')
        setSdgs(response.data.data)
        setLoading(false)
      } catch (err) {
        // console.error(err)
        setError(err.toString())
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  // Manage display cycling
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDisplay((prevDisplay) => (prevDisplay + 1) % displays.length)
    }, 4000)

    return () => clearInterval(intervalId)
  }, [displays.length])

  // Fetch user subscriptions if authenticated
  useEffect(() => {
    const fetchUserSubscriptions = async () => {
      if (!userId) {
        // console.error('No User Id found')
        return
      }
      try {
        const response = await axiosInstance.get(
          `/api/users/${userId}?populate=*`,
        )
        const userData = response.data
        const subscriptions = userData.SDG_Subscription.map((sdg) => sdg.id)
        setUserSubscriptions(subscriptions)
      } catch (e) {
        // console.error('Failed to fetch user subscriptions', e)
      }
    }

    fetchUserSubscriptions()
  }, [userId])

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error}</div>

  const display = displays[currentDisplay]

  return (
    <div>
      <SmallHero
        imageUrl={display.imageUrl}
        buttonText={display.buttonText}
        onButtonClick={display.buttonAction}
        text={display.text}
        fontColor={display.fontColor}
      />
      <SDGCardTitleContainer>
        <SDGCardTitle>SDG GROUP</SDGCardTitle>
        <DropdownContainer>
          <DropdownMenu
            options={['Newest', 'Active', 'Popular']}
            placeholder="Sort by: Newest"
          />
        </DropdownContainer>
      </SDGCardTitleContainer>
      <SDGCardContainer>
        {sdgs.map((sdg) => (
          <SDGCard
            key={sdg.id}
            sdgImage={sdg.attributes.Banner.data.attributes.url}
            title={sdg.attributes.Title}
            keyword1={sdg.attributes.Tags.Tags[0]}
            keyword2={sdg.attributes.Tags.Tags[1]}
            keyword3={sdg.attributes.Tags.Tags[2]}
            keyword4={sdg.attributes.Tags.Tags[3]}
            keyword5={sdg.attributes.Tags.Tags[4]}
            lastActive={sdg.attributes.Last_Active}
            description={sdg.attributes.Overview}
            isSubscribed={userSubscriptions.includes(sdg.id)}
            onSubscribeClick={(isSubscribed) =>
              handleSubscribe(sdg.id, isSubscribed)
            }
            onJoinClick={() => handleJoinAndNavigate(sdg.id)}
            membersImage={MembersImage}
            membersText={`Join ${sdg.attributes.Total_Members}+ Members`}
          />
        ))}
      </SDGCardContainer>
      <Container>
        <YouTubeContainer>
          <H4Text>Who we are</H4Text>
          <YouTubeShowContainer>
            <NavLink to="/">Show More</NavLink>
          </YouTubeShowContainer>
        </YouTubeContainer>
        <YouTubeVideoContainer>
          <iframe
            src="https://www.youtube.com/embed/9jEL3z4pOTg"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </YouTubeVideoContainer>
      </Container>
    </div>
  )
}

const SDGCardTitleContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 0 5%;
  margin: 5rem 0 2rem 0;
`

const DropdownContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 5%;
  font-size: 15px;
`

const SDGCardTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: 600;
  color: #2f632c;
`

const SDGCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 1rem;
  padding: 0 5%;
  justify-content: center;
  @media (max-width: ${size.desktopLarge}) {
  }
  @media (max-width: ${size.desktop}) and (min-width: ${size.tablet}) {
  }
  @media (max-width: ${size.tablet}) and (min-width: ${size.mobile}) {
    gap: 3rem;
  }
  @media (max-width: ${size.mobile}) {
    gap: 3rem;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 50px 100px;
`
const YouTubeContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 0 5%;
  margin: 5rem 0 2rem 0;
`
const YouTubeShowContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 5%;
  font-size: 19px;
  font-weight: 700;
  align-items: center;
  a {
    text-decoration: none;
    color: #5a430e;
  }
`
const YouTubeVideoContainer = styled.div`
  width: 100%;
  padding-top: 56.25%;
  align-items: center;
  justify-content: center;
  position: relative;
  iframe {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 90%;
  }
`

export default Subscription
