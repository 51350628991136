// config/axiosConfig.js
import axios from 'axios'
// require('dotenv').config()

// Create an axios instance
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:1337', // Use env variable or fallback to localhost
  timeout: 5000, // Set a timeout for requests (optional)
  headers: {
    'Content-Type': 'application/json',
  },
})

// Add a request interceptor to include the auth token if available
instance.interceptors.request.use(
  (config) => {
    const token = process.env.REACT_APP_STRAPI_API_TOKEN
    if (token) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

export default instance
