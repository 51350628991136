import { useMemo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { T11RContent, T11SContent, T7FH, T4FH } from './Typography'
import { size } from '../../../assets/styles/mediaBreakpoints'
import ProfileImage from '../../../assets/images/ProfileImage.png'

const UserProfile = ({ email, name, disableTitle, hamburgerMenu }) => {
  const userInfo = useMemo(
    () => ({
      email,
      name,
    }),
    [email, name],
  )
  return (
    <ProfileContainer hamburgerMenu={hamburgerMenu}>
      {disableTitle ? null : (
        <SectionsTitle>
          <T11RContent color="#323941">Profile</T11RContent>
        </SectionsTitle>
      )}
      <PersonalInformationContainer>
        <ProfileImageContainer
          src={ProfileImage}
          alt="Profile Image"
          hamburgerMenu={hamburgerMenu}
        />
        <NameAndAddressContainer
          disableBottomMargin={hamburgerMenu}
          disableTopMargin={hamburgerMenu}
        >
          {!hamburgerMenu ? (
            <>
              <T7FH color="0a0a0a" margin="0 0 10px 0">
                {userInfo.name}
              </T7FH>
              <T11RContent color="#64748b">{userInfo.email}</T11RContent>
            </>
          ) : (
            <>
              <T4FH color="#0a0a0a" margin="0 0 10px 0">
                {userInfo.name}
              </T4FH>
              <T11SContent color="#64748b">{userInfo.email}</T11SContent>
            </>
          )}
        </NameAndAddressContainer>
      </PersonalInformationContainer>
    </ProfileContainer>
  )
}

UserProfile.propTypes = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disableTitle: PropTypes.bool,
  hamburgerMenu: PropTypes.bool,
}

UserProfile.defaultProps = {
  disableTitle: false,
  hamburgerMenu: false,
}

export default UserProfile

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => (props.hamburgerMenu ? 'flex-start' : 'center')}
  width: 100%;

  @media (max-width: ${size.tablet}) {
    align-items: flex-start;
    padding-bottom: 12px;
  }
`

const PersonalInformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const ProfileImageContainer = styled.img`
  border-radius: 50%;
  width: ${(props) => (props.hamburgerMenu ? '35px' : '56px')};
  height: ${(props) => (props.hamburgerMenu ? '35px' : '56px')};
`

const NameAndAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: ${(props) => (props.disableTopMargin ? '0px' : '20px')};
  padding-bottom: ${(props) => (props.disableBottomMargin ? '0px' : '20px')};

  @media (max-width: ${size.tablet}) {
    padding: 12px;
  }
`

const SectionsTitle = styled.div`
  align-self: flex-start;
  padding: 10px;

  @media (max-width: ${size.tablet}) {
    padding: 5px;
  }
`
