import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { useState } from 'react'
import CoGsLogo from '../../../assets/images/Logo.png'
import { PrimaryButton } from '../../../components/common/ui/Button'
import Input from '../../../components/common/form/Input'
import { size } from '../../../assets/styles/mediaBreakpoints'
import { H4Text, T11RContent } from '../../../components/common/ui/Typography'
import axiosInstance from '../../../config/AxiosInstance'

const ResetPassword = () => {
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [confirmationMessage, setConfirmationMessage] = useState('')
  const [passwordChanged, setPasswordChanged] = useState(false)
  const [searchParams] = useSearchParams()
  const resetPasswordToken = searchParams.get('code')

  const handlePasswordSubmit = async (event) => {
    event.preventDefault()
    axiosInstance
      .post(
        'api/auth/reset-password',
        {
          code: resetPasswordToken,
          password,
          passwordConfirmation,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then(() => {
        setConfirmationMessage('Password reset successfully')
        setPasswordChanged(true)
      })
      .catch(() => {
        setConfirmationMessage('An error occurred. Please try again.')
      })
  }

  return (
    <Container>
      <ComponentContainer>
        <ContentContainer>
          <Logo src={CoGsLogo} alt="CoGs Logo" />
          <H4Text>Reset Password</H4Text>
          <T11RContent>Please enter your new password.</T11RContent>
          <ConfirmationForm onSubmit={handlePasswordSubmit}>
            <Input
              title="New Password"
              inputType="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Input
              title="New Password Confirmation"
              inputType="password"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
            <PrimaryButton type="submit">Reset Password</PrimaryButton>
          </ConfirmationForm>
          {passwordChanged ? (
            <LinkWrapper>
              <div>
                <T11RContent color="green">
                  Your password has been reset successfully.
                </T11RContent>
                <T11RContent>
                  Please<Link href="/signin">sign in</Link>to continue.
                </T11RContent>
              </div>
            </LinkWrapper>
          ) : (
            <T11RContent color="red">{confirmationMessage}</T11RContent>
          )}
        </ContentContainer>
      </ComponentContainer>
    </Container>
  )
}

export default ResetPassword

const ConfirmationForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 80%;
  max-width: 800px;
  max-height: 700px;
  margin: 0 auto;

  @media (max-width: ${size.tablet}) {
    flex-direction: column;
    align-items: center;
  }
`

const ComponentContainer = styled.div`
  background: #f0efeb;
  height: auto;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  padding: 20px;
  margin: 30px auto;
  width: 100%;
  max-width: 800px;

  @media (max-width: ${size.tablet}) {
    padding: 10px;
    margin: 15px auto;
    max-width: 100%;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: evenly;
  width: 100%;
`

const Logo = styled.img`
  max-width: 330px;
  height: auto;
  margin: 20px;

  @media (max-width: ${size.tablet}) {
    max-width: 150px;
    margin: 10px;
  }
`

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px 0 0 0;
  text-align: center;
`

const Link = styled.a`
  color: #a0522e;
  text-decoration: none;
  margin: 0 5px;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`
