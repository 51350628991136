export const breakpoints = {
  mobile: 480,
  tablet: 768,
  desktop: 1200,
  desktopLarge: 1500,
  desktopWide: 1920,
}

export const size = {
  mobile: `${breakpoints.mobile}px`,
  tablet: `${breakpoints.tablet}px`,
  desktop: `${breakpoints.desktop}px`,
  desktopLarge: `${breakpoints.desktopLarge}px`,
  desktopWide: `${breakpoints.desktopWide}px`,
}

export const device = {
  mobile: `(min-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopLarge: `(min-width: ${size.desktopLarge})`,
  desktopWide: `(min-width: ${size.desktopWide})`,
}
