import React from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MdOutlineLibraryBooks } from 'react-icons/md'
import { IoCreateOutline } from 'react-icons/io5'
import { size } from '../../../assets/styles/mediaBreakpoints'
import { Span, T11RContent, T7FH } from '../ui/Typography'
import UserProfile from '../ui/UserProfile'

const MenuItem = ({ Icon, title, onClick }) => {
  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <MenuItemContainer onClick={handleClick}>
      <Span size="24px" weight="600">
        <IconAndTitleContainer>
          <Icon style={{ marginRight: '5px', marginLeft: '5px' }} />
          <T7FH color="2f632c">{title}</T7FH>
        </IconAndTitleContainer>{' '}
      </Span>
    </MenuItemContainer>
  )
}

const ProfileMenu = ({ email, name, onMakePostClick, onAllPostsClick }) => {
  const location = useLocation()

  return (
    <ComponentContainer>
      <UserProfile email={email} name={name} />
      <Separator />
      <MenuContainer>
        <SectionsTitle>
          <T11RContent color="#64748B" weight="600">
            Menu
          </T11RContent>
        </SectionsTitle>
        <>
          <MenuItem
            Icon={MdOutlineLibraryBooks}
            title="All Posts"
            currentPath={location.pathname}
            onClick={onAllPostsClick}
          />
          <MenuItem
            Icon={IoCreateOutline}
            title="Make a Post"
            currentPath={location.pathname}
            onClick={onMakePostClick}
          />
        </>
      </MenuContainer>
    </ComponentContainer>
  )
}

ProfileMenu.propTypes = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onMakePostClick: PropTypes.func.isRequired,
  onAllPostsClick: PropTypes.func.isRequired,
}

MenuItem.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

MenuItem.defaultProps = {
  onClick: null,
}

export default ProfileMenu

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-width: 288px;
  max-width: 300px;
  height: auto;
  background-color: #f0efeb;
`

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d0d5dd;
`

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  width: 100%;
  padding: 24px;
`

const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px 16px 12px;
  width: 100%;
  cursor: pointer;
`

const IconAndTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`

const SectionsTitle = styled.div`
  align-self: flex-start;
  padding: 10px;

  @media (max-width: ${size.tablet}) {
    padding: 5px;
  }
`
