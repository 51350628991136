import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { SecondaryButton } from './Button'
import { H3SB } from './Typography'
import { device } from '../../../assets/styles/mediaBreakpoints'

const SmallHero = ({
  imageUrl,
  buttonText,
  onButtonClick,
  text,
  fontColor,
}) => {
  return (
    <Container>
      <ImageWrapper>
        <StyledImage src={imageUrl} alt="Hero" />
        <HeroContent>
          {text && <H3SB color={fontColor}>{text}</H3SB>}
          {buttonText && (
            <StyledButton onClick={onButtonClick}>{buttonText}</StyledButton>
          )}
        </HeroContent>
      </ImageWrapper>
    </Container>
  )
}

SmallHero.propTypes = {
  imageUrl: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  text: PropTypes.string,
  fontColor: PropTypes.string,
}

SmallHero.defaultProps = {
  imageUrl: '',
  buttonText: '',
  onButtonClick: () => {},
  text: '',
  fontColor: '#0a0a0a',
}

export default SmallHero

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  display: inline-block; // Make sure it can be relative to HeroContent
`

const StyledImage = styled.img`
  width: 100%;
  height: auto; // Ensures the image is responsive and maintains its aspect ratio
  display: block; // Removes any space below the image
  transition: opacity 0.5s ease-in-out;
`

const HeroContent = styled.div`
  position: absolute;
  top: 20%;
  left: 5%;
  text-align: left;
  color: ${(props) => props.fontColor};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  transition: opacity 0.5s ease-in-out;

  @media ${device.tablet} {
    left: 10%;
    width: 80%; // Adjusts width on larger devices for better readability
  }
`

const StyledButton = styled(SecondaryButton)`
  padding: 0px 24px;
  border-radius: 16px;
  width: 275px;
  height: 50px;
  font-weight: 500;

  @media ${device.tablet} {
    font-size: 15px; // Adjust font size for larger screens
  }
  @media ${device.desktop} {
    font-size: 19px; // Adjust font size for larger screens
  }
  @media ${device.desktopLarge} {
    font-size: 22px; // Adjust font size for larger screens
  }
`
