import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [jwtToken, setJwtToken] = useState(() => {
    return sessionStorage.getItem('jwtToken')
  })
  const [userId, setUserId] = useState(null)
  const [userEmail, setUserEmail] = useState(() => {
    return sessionStorage.getItem('userEmail')
  })
  const [userName, setUserName] = useState(() => {
    return sessionStorage.getItem('userName')
  })

  const decodeToken = useCallback((token) => {
    const base64Url = token.split('.')[1]
    if (!base64Url) return null

    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''),
    )

    try {
      const parsedData = JSON.parse(jsonPayload)
      return parsedData
    } catch (e) {
      // console.error('Error parsing JSON:', e)
      return null
    }
  }, [])

  const login = useCallback(
    (data) => {
      setJwtToken(data.jwt)
      sessionStorage.setItem('jwtToken', data.jwt)
      const userData = decodeToken(data.jwt)
      setUserId(userData.id)
      setUserEmail(data.user.email)
      setUserName(data.user.username)
      sessionStorage.setItem('userEmail', data.user.email)
      sessionStorage.setItem('userName', data.user.username)
    },
    [decodeToken],
  )

  const logout = useCallback(() => {
    setJwtToken(null)
    setUserId(null)
    setUserEmail(null)
    setUserName(null)
    sessionStorage.removeItem('jwtToken')
    sessionStorage.removeItem('userEmail')
    sessionStorage.removeItem('userName')
  }, [])

  const value = useMemo(
    () => ({
      jwtToken,
      userId,
      userEmail,
      userName,
      isAuthenticated: !!jwtToken,
      login,
      logout,
    }),
    [jwtToken, userId, userEmail, userName, login, logout],
  )

  useEffect(() => {
    const token = sessionStorage.getItem('jwtToken')
    if (token) {
      setJwtToken(token)
      const userData = decodeToken(token)
      setUserId(userData.id)
      setUserEmail(sessionStorage.getItem('userEmail'))
      setUserName(sessionStorage.getItem('userName'))
    }
  }, [decodeToken])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useAuth = () => useContext(AuthContext)
