import React from 'react'
import styled from 'styled-components'
import VerticalLinkList from '../../components/common/form/VerticalLinkList'
import {
  T6Content,
  H4Text,
  T15BContent,
} from '../../components/common/ui/Typography'

const Terms = () => {
  const links = [
    { id: '1', title: 'Our Members', to: 'our-members-section' },
    { id: '2', title: 'Our individual Members', to: 'our-individual-members' },
    { id: '3', title: 'Our Knowledge Experts', to: 'our-knowledge-experts' },
    { id: '4', title: 'Our Ambassadors', to: 'our-ambassadors' },
    { id: '5', title: 'The Challenge', to: 'the-challenge' },
    { id: '6', title: 'Challenge Guidelines', to: 'challenge-guidelines' },
  ]

  return (
    <div>
      <LayoutContainer>
        <ContentArea>
          <section id="our-members-section">
            <br />
            <br />
            <H4Text>Our Members</H4Text>
            <br />
            <br />
            <T6Content>
              Membership in the CoG&apos;s is open to all citizens of the world
              who have access to a device that can access the platform. In
              support of Individual members, there will be Verified Scientists
              or Knowledge Experts who will guide and share lessons on providing
              best practices and suggestions for group action. The importance of
              including a strong knowledge base is important as it gives a voice
              to Scientists and other Knowledge Experts who have spent much of
              their lives studying the subjects that can help solve the problems
              we aim to tackle. The CoG&apos;s platform will give them a voice
              to share their knowledge and educate an interested audience who is
              eager to find tangible ways to tackle big problems. Alongside
              Individual members and Knowledge Experts, the Cog&apos;s have a
              third actor the Verified Ambassador, whose job is to champion a
              cause and share their celebrity status with Science in pursuit of
              a noble goal. Each SDG group can have as many Ambassadors and
              Knowledge experts who choose to join that particular SDG group to
              address that particular SDG. Each of these members will need to be
              verified by the CoG&apos;s before they can be viewed as this type
              of member.
              <br />
              <br />
              Note:Individual who may also be known as knowledge experts or
              ambassadors are also welcome to join as an individual member but
              to be labelled on the platform or to serve in a &quot;knowledge
              Expert&quot; or &quot;Ambassador&quot; role one must be verified
              and confirmed. This is an important step to ensure the legitimacy
              of the site and ensure members are being guided and championed by
              legitimate actors.
            </T6Content>
          </section>
          <br />
          <br />
          <section id="our-individual-members">
            <H4Text>Our individual Members</H4Text>
            <br />
            <br />
            <T6Content>
              The majority of members will be classified as &quot;individual
              members&quot;. Any citizen from any geographical location across
              the world can join as an individual member. There are no extra
              verification steps to join as an individual member but each member
              will be required to agree to the site membership agreement and the
              code of conduct requirements. The Individual group members can
              then, after reviewing the various challenges within their SDGs
              group, accept a Challenge. Once accepted, members can post
              pictures and questions as they progress and work toward completing
              the challenge. After completing a challenge, each member will be
              requested to complete a questionnaire, where information can be
              collected to monitor success and achieve rewards points.
            </T6Content>
          </section>
          <br />
          <br />
          <section id="our-knowledge-experts">
            <H4Text>Our Knowledge Experts</H4Text>
            <br />
            <br />
            <T6Content>
              To join the community as a &quot;knowledge Expert&quot; the member
              is requested to verify their identity and educational or
              work-related experience that justifies their role as a Knowledge
              Expert. This is an important step as it allows us to ensure that
              the sources of information being shared with each group are valid
              and follow scientific rigour. Science is an important means of
              producing knowledge and getting to what approximates the truth.
              Good science results from rigorous processes. Part of the rigour
              in science and knowledge creation is the peer review process,
              which is a means of ensuring not only the correctness of facts but
              also transparency. Empirical data used in science that can be
              verified forms a sound basis for robust discussion, debate and
              decision-making. Science brings a degree of rationality that
              creates a higher probability that the best interest of society or
              the public interest will be taken into account in, for example,
              decision-making. This enables the SDG groups to be led by
              Knowledge Experts to participate in decision-making and
              progressive scientific principles. Knowledge Experts allow science
              to be disseminated in ways that are understood and are important
              for building trust. Knowledge Experts will share lessons and facts
              within the applicable SDG private group areas as well the most
              important part, &quot;A Challenge&quot;. The Challenge will be
              posted exclusively by &quot;knowledge experts&quot;who will then
              post the challenge after sharing knowledge about the issue as it
              relates to the SDGs group.
            </T6Content>
          </section>
          <br />
          <br />
          <section id="our-ambassadors">
            <H4Text>Our Ambassadors</H4Text>
            <br />
            <br />
            <T6Content>
              To join the community as an Ambassador the member is requested to
              verify their identity and provide information that justifies their
              role as an Ambassador. This is an important step as Ambassadors
              will rally the participants and use their fame and visibility to
              encourage and inspire the group to meet the goals outlined by
              Knowledge Expert. This verification step is important to ensure
              the safety and validity of the community. We must ensure that both
              Ambassadors and Knowledge Experts are who they say they are and
              that no one is masquerading as someone they are not. This
              jeopardizes the legitimacy of the whole community and we ask that
              Ambassadors be supportive of this verification step as we truly
              value the role of Ambassadors and want to protect our members by
              knowing that Ambassadors are truly who they say they are.
              Ambassadors will serve an important role in rallying the group of
              people who have chosen a particular challenge to accept. They can
              positively use their fame to encourage more memberships and can
              provide moral boosting confidence to the group as it is recognized
              that these are big challenges we face.
            </T6Content>
          </section>
          <br />
          <br />
          <section id="the-challenge">
            <H4Text>The Challenge</H4Text>
            <br />
            <br />
            <T6Content>
              Knowledge Experts will be responsible for posting
              &quot;Challenges&quot;. The Challenge will be posted exclusively
              by &quot;knowledge experts&quot; who will then post this challenge
              after sharing knowledge about the issue as it relates to the SDGs
              group. The knowledge expert will be given some common guidelines
              for posting a challenge to ensure challenges are consistent across
              all groups and measurable. There will also be a &quot;Once a
              Day&quot; Challenge listed on the main page that users who have
              not joined an SDGs group yet or those seeking an easy way to
              quickly contribute and earn reward points can accept. This
              challenge can be collected from other SDG&apos;s group challenges
              as a way for users to see what other groups are doing. Challenges
              can only be issued by Knowledge Experts but each challenge can
              have as many members, Knowledge Experts or Ambassadors as
              required, depending on the interest, size and scope of the
              challenge. Challenges can proceed without Ambassadors and we
              expect the early challenges as the community grows to have many
              challenges with only a knowledge expert on board. We are hoping as
              the community grows fans will encourage famous ambassadors to
              join.
            </T6Content>
          </section>
          <br />
          <br />
          <section id="challenge-guidelines">
            <H4Text>Chanllenge Guidelines</H4Text>
            <br />
            <br />
            <T6Content>
              Advice for Knowledge Experts before posting a challenge:
              <br />
              <br />
              1.Raise awareness through broad group engagement and learning by
              posting lessons from your area of expertise and how it relates to
              solving an SDG&apos;s issues, and then connecting the SDGs to the
              groups interests to inspire and build momentum for change
              <br />
              <br />
              2.Consult on local priorities to develop a shared vision
              <br />
              <br />
              3.Understand your group&apos;s progress to celebrate success and
              galvanize action
              <br />
              <br />
              4.Align existing group plans with the Global Goals
              <br />
              <br />
              5.Develop a group challenge for advancing priority goals, ensuring
              the challenge has clear steps to complete and are measurable. (see
              Data collection requirements to officially complete a challenge)
              <br />
              <br />
              6.Invite diverse voices to participate, offering support to enable
              participation
              <br />
              <br />
              7.Identify meaningful indicators that resonate with your community
              to measure progress and encourage group members to post their
              progress on the group and main page to inspire others
              <br />
              <br />
              8.Once you are ready to post your Challenge send it to group
              administrators to approve and to post
            </T6Content>
          </section>
          <br />
          <br />
          <br />
        </ContentArea>
        <ContentArea2>
          {' '}
          <T15BContent>
            <VerticalLinkList links={links} />{' '}
          </T15BContent>
        </ContentArea2>
      </LayoutContainer>
    </div>
  )
}

const LayoutContainer = styled.div`
  display: flex;
`

const ContentArea = styled.div`
  flex: 2; // Adjust this value as needed for your layout
  margin-right: 130px; // Provides spacing between the content and the list
  padding: 0px 0px 0px 50px;
`
const ContentArea2 = styled.div`
  flex: 1; // Adjust this value as needed for your layout
  margin-top: 50px;
  margin-right: 40px;
`

export default Terms
