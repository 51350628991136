import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { SecondaryButton, TertiaryButton } from './Button'
import { H3SB } from './Typography'
import { device, size } from '../../../assets/styles/mediaBreakpoints'

const Hero = ({
  title,
  buttonText1,
  buttonText2,
  buttonOnClick1,
  imageUrl,
  fontColor,
}) => {
  const navigate = useNavigate()

  const handleButton2Click = () => {
    navigate('/subscription')
  }

  return (
    <HeroWrapper style={{ backgroundImage: `url(${imageUrl})` }}>
      <HeroContent>
        <H3SB color={fontColor} margin="0 0 20px 5px">
          {title}
        </H3SB>
        <ButtonContainer>
          <StyledSecondaryButton onClick={buttonOnClick1}>
            {buttonText1}
          </StyledSecondaryButton>
          <StyledTertiaryButton onClick={handleButton2Click}>
            {buttonText2}
          </StyledTertiaryButton>
        </ButtonContainer>
      </HeroContent>
    </HeroWrapper>
  )
}

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText1: PropTypes.string.isRequired,
  buttonText2: PropTypes.string.isRequired,
  buttonOnClick1: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
  fontColor: PropTypes.string,
}

Hero.defaultProps = {
  fontColor: '#E0DED7',
}

export default Hero

const HeroWrapper = styled.div`
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center;
  height: calc(100vh - 85px);

  @media ${device.tablet} {
    height: calc(100vh - 85px);
  }
`

const HeroContent = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${device.tablet} {
    padding: 0 0 0 100px;
  }
`

const StyledSecondaryButton = styled(SecondaryButton)`
  width: 356px;
  height: 66px;
  border-radius: 16px;
  gap: 0;
  font-weight: 500;
  box-shadow: 0px 4px 4px 0px #f3fadc40;

  &:hover {
    background-color: #2f632c;
    color: #f3fadc;
  }

  @media (max-width: ${size.tablet}) {
    width: 300px;
    height: 60px;
    padding: 0px 20px;
  }

  @media (max-width: ${size.mobile}) {
    width: 280px;
    height: 55px;
    padding: 0px 16px;
  }
`

const StyledTertiaryButton = styled(TertiaryButton)`
  padding: 0;
  width: 100%;
  border-radius: 16px;
  border: 2px solid #bbe148;
  height: 50px;
  font-weight: 500;
  color: #f3fadc;

  &:hover {
    border-color: #2f632c;
  }

  @media (max-width: ${size.tablet}) {
    width: 300px;
    height: 60px;
    padding: 0px 20px;
  }

  @media (max-width: ${size.mobile}) {
    width: 280px;
    height: 55px;
    padding: 0px 16px;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: flex-start;
`
