export const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
export const MIN_PASSWORD_LENGTH = 8
export const BORDER_COLOR = {
  valid: 'green',
  invalid: 'red',
  neutral: '#8a8a8a',
}
export const INPUT_TYPES = {
  text: 'text',
  email: 'email',
  password: 'password',
}
export const REQUEST_STATUS = {
  idle: 'idle',
  loading: 'loading',
  success: 'success',
  error: 'error',
}
