import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Post from '../../../features/posts/Post' // Ensure this is the right path

const Thread = ({ posts = [] }) => {
  if (!posts.length) {
    return <NoPostsMessage>No posts available</NoPostsMessage>
  }
  return (
    <ThreadComponent>
      {posts.map((post) => (
        <Post key={post.id} data={{ ...post, replies: post.replies || [] }} />
      ))}
    </ThreadComponent>
  )
}

Thread.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired, // Assumed 'id' is a number
      userName: PropTypes.string, // Assumed 'userName' is a string
      userAvatar: PropTypes.string, // Assumed 'userAvatar' is a string URL
      timeAgo: PropTypes.string, // Assumed 'timeAgo' is a string
      title: PropTypes.string, // Assumed 'title' is a string
      description: PropTypes.string, // Assumed 'description' is a string
      likes: PropTypes.number, // Assumed 'likes' is a number
      replies: PropTypes.arrayOf(
        PropTypes.shape({
          // Assumed 'replies' is an array of objects
          // Define the shape of a reply if available
        }),
      ),
      images: PropTypes.arrayOf(
        PropTypes.shape({
          // Assumed 'images' is an array of objects
          // Define the shape of an image if available
        }),
      ),
    }),
  ).isRequired, // Mark as required if there's no sensible default
}

export default Thread

const ThreadComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin: 20px auto;
  padding: 20px;
  background-color: white;
  width: 100%;
  min-width: 400px;
  max-width: 700px;
  overflow-y: auto;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`

const NoPostsMessage = styled.p`
  color: gray;
`
