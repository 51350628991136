import React from 'react'
import styled from 'styled-components'
import VerticalLinkList from '../../components/common/form/VerticalLinkList'
import {
  T6Content,
  H4Text,
  T15BContent,
} from '../../components/common/ui/Typography'
import { size } from '../../assets/styles/mediaBreakpoints'

const Membership = () => {
  const links = [
    { id: '1', title: 'Membership Agreement', to: 'membership-agreement' },
    {
      id: '2',
      title: 'Please note the following',
      to: 'following',
    },
    { id: '3', title: 'The pledge', to: 'pledge' },
  ]

  return (
    <div>
      <LayoutContainer>
        <ContentArea>
          <section id="membership-agreement">
            <br />
            <br />
            <div>
              <H4Text>Membership Agreement</H4Text>
            </div>
            <br />
            <br />
            <T6Content>
              The “The Community of Guardians” (CoGs) is a platform whose
              mission is “Inspiring the world to take Action”
              <br />
              <br />
              <li>
                A place where members can take action on meeting SDGs; it
                provides a platform to learn, participate, discuss and celebrate
                accomplishments.
              </li>
              <br />
              <li>
                This community is comprised of passionate people who are ready
                to tackle some of our world&apos;s most serious problems, guided
                by Science and encouraged by ambassadors.
              </li>
            </T6Content>
          </section>
          <br />
          <br />
          <br />
          <section id="following">
            <H4Text>Please note the following</H4Text>
            <br />
            <br />
            <T6Content>
              <li>
                The “CoGs - The Community of Guardians” aims to offer a virtual
                safe place where members can learn, participate, share and
                support one another in meeting (SDG) goals.
              </li>
              <br />
              <li>
                Our vision is for members from all over the world to join and
                collaborate, learn and take tangible actions; a place where
                members can form connections, build bridges and share knowledge.
              </li>
              <br />
              <li>
                This network seeks to create a space where the focus is on our
                actions. We know the issues, we know the solutions… let us take
                action together to solve problems.
              </li>
              <br />
              <li>
                The network aims to empower members so they can become a source
                of ongoing support to other members. A place where knowledge
                experts can share science and solutions that help us shift the
                needle in meeting our 2030 SDGs targets.
              </li>
              <br />
              <li>
                By participating in challenges, posting or responding to posts,
                please be courteous, kind, considerate, and caring.
              </li>
              <br />
              <li>
                In agreeing to become a member of the CoGs please acknowledge
                the values of this organization which are:
              </li>
              <br />
              <strong>Collaborative</strong>- there is no way each of us can do
              this alone!
              <br />
              <br /> <strong>Innovative</strong>- be open to innovation, action
              on this scale requires novel solutions.&nbsp;&nbsp;
              <br />
              <br /> <strong>Passionate</strong>- be willing to work hard,
              solutions of this scale require one to truly care about the
              problem.&nbsp;&nbsp;
              <br />
              <br /> <strong>Believe the impossible</strong>- there is always a
              solution we just have not found it yet.&nbsp;&nbsp;
            </T6Content>
          </section>
          <br />
          <br /> <br />
          <section id="pledge">
            <H4Text>The pledge</H4Text>
            <br />
            <br />
            <T6Content>
              By joining this organization, you pledge to:
              <br />
              <br />
              <li>Remain kind and courteous at all times,</li>
              <br />
              <li>Never use hate speech or bullying,</li>
              <br />
              <li>Build trust through authentic and expressive discussions</li>
              <br />
            </T6Content>
          </section>
          <br />
          <br />
        </ContentArea>
        <ContentArea2>
          <T15BContent>
            <VerticalLinkList links={links} />
          </T15BContent>
        </ContentArea2>
      </LayoutContainer>
    </div>
  )
}

const LayoutContainer = styled.div`
  display: flex;
  position: relative;

  @media (max-width: ${size.tablet}) {
    display: block;
  }
`

const ContentArea = styled.div`
  flex: 2;
  padding: 0px 0px 0px 50px;

  @media (min-width: ${size.desktopLarge}) {
    padding: 0px 0px 0px 60px;
  }

  @media (max-width: ${size.desktop}) {
    padding: 0px 50px;
  }

  @media (max-width: ${size.tablet}) {
    padding: 0px 30px;
  }
`

const ContentArea2 = styled.div`
  flex: 1;
  padding: 40px 0px;

  @media (max-width: ${size.tablet}) {
    position: fixed;
    top: 0;
    right: 0;
    width: auto;
    height: auto;
    padding: 10px;
    z-index: 1000; // Ensure it's above other content
  }
`

export default Membership
