import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import { IoCameraOutline } from 'react-icons/io5'
import { TbPhoto } from 'react-icons/tb'
import { BsEmojiSmile } from 'react-icons/bs'
import ProfileImage from '../../../../assets/images/ProfileImage.png'
import { size } from '../../../../assets/styles/mediaBreakpoints'
import { useAuth } from '../../../../context/AuthContext'
import axiosInstance from '../../../../config/AxiosInstance'

const PostComposer = ({ name = '', onPostSuccess }) => {
  const [postContent, setPostContent] = useState('')
  const [images, setImages] = useState([])
  const [videos, setVideos] = useState([])
  const [error, setError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { userId } = useAuth()

  const imageInputRef = useRef(null)
  const videoInputRef = useRef(null)

  const firstName = name.split(' ')[0]

  const handlePostContentChange = (event) => {
    setPostContent(event.target.value)
  }

  const handleImageChange = (event) => {
    const fileArray = Array.from(event.target.files)
    const imageFiles = fileArray.map((file) => ({
      id: uuidv4(),
      file,
      preview: URL.createObjectURL(file),
    }))
    setImages([...images, ...imageFiles])
  }

  images.map((img) => (
    <img
      key={img.id}
      src={img.preview}
      alt="preview"
      style={{ width: 50, height: 50 }}
    />
  ))

  const handleVideoChange = (event) => {
    const fileArray = Array.from(event.target.files)
    const videoFiles = fileArray.map((file) => ({
      id: uuidv4(),
      file,
      preview: URL.createObjectURL(file),
    }))
    setVideos([...videos, ...videoFiles])
  }

  const handleSubmit = async () => {
    if (!postContent.trim() && images.length === 0 && videos.length === 0) {
      setError('Please add some content or upload images/videos to post.')
      return
    }

    setIsSubmitting(true)
    if (!userId) {
      setError('Authentication error. Please log in again.')
      setIsSubmitting(false)
      return
    }

    try {
      const formData = new FormData()
      images.forEach((image) => formData.append('files', image.file))
      videos.forEach((video) => formData.append('files', video.file))

      let imageIds = []
      let videoIds = []

      if (images.length > 0 || videos.length > 0) {
        const uploadResponse = await axiosInstance.post(
          '/api/upload',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )

        imageIds = uploadResponse.data
          .filter(
            (file) =>
              file.url.endsWith('.png') ||
              file.url.endsWith('.jpg') ||
              file.url.endsWith('.jpeg'),
          )
          .map((file) => file.id)

        videoIds = uploadResponse.data
          .filter(
            (file) => file.url.endsWith('.mp4') || file.url.endsWith('.avi'),
          )
          .map((file) => file.id)
      }

      const postData = {
        data: {
          Text: postContent,
          Likes: 0,
          Date_Created: new Date().toISOString(),
          Userid: userId,
          Images: imageIds.concat(videoIds),
        },
      }

      const postResponse = await axiosInstance.post('/api/posts', postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (postResponse.status === 200 || postResponse.status === 201) {
        // alert('Post created successfully!')
        setPostContent('')
        setImages([])
        setVideos([])
        onPostSuccess(postResponse.data)
      } else {
        throw new Error('Failed to create post')
      }
    } catch (fetchError) {
      // console.error('Error creating post:', fetchError)
      setError('Failed to post. Please try again later.')
    }
    setIsSubmitting(false)
  }

  const videoElements = videos.map((video) => (
    <video
      key={video.id}
      src={video.preview}
      alt="Video preview"
      controls
      style={{ width: 100, height: 100, margin: 5 }}
    >
      <track kind="captions" />
    </video>
  ))
  return (
    <PostComposerContainer>
      <ProfileImageAndSearchBarContainer>
        <ProfileImageContainer src={ProfileImage} alt="Profile Image" />
        <SearchBarContainer
          type="text"
          placeholder={`What's on your mind, ${firstName}?`}
          value={postContent}
          onChange={handlePostContentChange}
          disabled={isSubmitting}
        />
      </ProfileImageAndSearchBarContainer>
      {error && <p>{error}</p>}
      <ButtonsContainer>
        <MediaUploadButtonsContainer>
          {images.map((img) => (
            <img
              key={img.id}
              src={img.preview}
              alt="preview"
              style={{ width: 50, height: 50 }}
            />
          ))}
          {videoElements}

          <MediaUploadButton onClick={() => imageInputRef.current.click()}>
            <IoCameraOutline style={{ fontSize: '1.5rem', margin: '5px' }} />
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageChange}
              style={{ display: 'none' }}
              ref={imageInputRef}
            />
            <MediaUploadButtonFont>Photo</MediaUploadButtonFont>
          </MediaUploadButton>

          <MediaUploadButton onClick={() => videoInputRef.current.click()}>
            <TbPhoto style={{ fontSize: '1.5rem', margin: '5px' }} />
            <input
              type="file"
              accept="video/*"
              multiple
              onChange={handleVideoChange}
              style={{ display: 'none' }}
              ref={videoInputRef}
            />
            <MediaUploadButtonFont>Video</MediaUploadButtonFont>
          </MediaUploadButton>
          <MediaUploadButton>
            <BsEmojiSmile style={{ fontSize: '1.5rem', margin: '5px' }} />
            <MediaUploadButtonFont>Feeling</MediaUploadButtonFont>
          </MediaUploadButton>
        </MediaUploadButtonsContainer>
        <PostButton
          onClick={handleSubmit}
          disabled={
            isSubmitting ||
            (!postContent.trim() && images.length === 0 && videos.length === 0)
          }
        >
          Post
        </PostButton>
      </ButtonsContainer>
    </PostComposerContainer>
  )
}

PostComposer.propTypes = {
  name: PropTypes.string.isRequired,
  onPostSuccess: PropTypes.func.isRequired,
}

export default PostComposer

const PostComposerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #f0efeb;
  padding: 5px;
`

const ProfileImageAndSearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const ProfileImageContainer = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 10px;
`

const SearchBarContainer = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  border: 1px solid #d0d5dd;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  color: grey;
`

const MediaUploadButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`

const PostButton = styled.button`
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #2f632c;
  color: white;
  cursor: pointer;
  width: 100px;
  height: 40px;

  &:hover {
    background-color: #0056b3;
  }
`

const MediaUploadButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;

  &:hover {
    background-color: #f0f2f5;
    border-radius: 5px;
  }
`

const MediaUploadButtonFont = styled.span`
  font-size: 0.75rem;
  @media (max-width: ${size.mobile}) {
    font-size: 0.6rem;
  }
`
