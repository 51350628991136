import React, { useState } from 'react'
import styled from 'styled-components'
import { formatDistanceToNow } from 'date-fns'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import Profile from '../profile/Profile'
import { size } from '../../../assets/styles/mediaBreakpoints'
import Thread from '../../../components/common/unique/Thread'
import DropdownMenu from '../../../components/common/form/Dropdown'
import PostComposer from './PostComposer/PostComposer'
import axiosInstance from '../../../config/AxiosInstance'

const fetchPosts = async (filter, sortFunction) => {
  const validFilters = ['Date_Created', 'Likes', 'Replies']
  if (!validFilters.includes(filter)) {
    throw new Error(`Invalid filter: ${filter}`)
  }

  const sortParam =
    filter !== 'Replies' ? `${filter}:desc` : 'Date_Created:desc'
  const response = await axiosInstance.get(
    `/api/posts?populate=Userid,Replies,Images&sort=${sortParam}`,
  )

  if (!response.data || !response.data.data) {
    throw new Error('No data returned')
  }

  let posts = response.data.data
    .map((post) => {
      if (
        !post.attributes ||
        !post.attributes.Userid ||
        !post.attributes.Userid.data
      ) {
        // eslint-disable-next-line no-console
        console.warn('Missing data:', post)
        return null
      }
      const userDetails = post.attributes.Userid.data.attributes
      const postDate = new Date(post.attributes.Date_Created)
      const repliesCount = post.attributes.Replies
        ? post.attributes.Replies.data.length
        : 0
      const images =
        post.attributes.Images?.data?.map((img) => img.attributes.url) || []

      return {
        id: post.id,
        userName: userDetails.username,
        userAvatar:
          userDetails.Icon || 'https://source.unsplash.com/random/50x50',
        timeAgo: formatDistanceToNow(postDate, { addSuffix: true }),
        title: userDetails.username,
        description: post.attributes.Text,
        likes: parseInt(post.attributes.Likes, 10),
        replies: repliesCount,
        images,
      }
    })
    .filter(Boolean)
  if (sortFunction) {
    posts = posts.sort(sortFunction)
  }

  return posts
}

const sortPostsByReplies = (a, b) => b.replies - a.replies

const HomeFeed = () => {
  const queryClient = useQueryClient()
  const [filter, setFilter] = useState('Date_Created')
  const [isMakingPost, setIsMakingPost] = useState(false)

  const filterMapping = {
    'Most Recent': 'Date_Created',
    'Most Liked': 'Likes',
    'Most Commented': 'Replies',
  }

  const {
    data: posts,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['posts', filter],
    queryFn: () => {
      if (filter === 'Replies') {
        return fetchPosts(filter, sortPostsByReplies)
      }
      return fetchPosts(filter)
    },
  })

  const handleFilterChange = (selectedOption) => {
    const backendFilter = filterMapping[selectedOption] // Map to backend term
    setFilter(backendFilter)
    queryClient.invalidateQueries(['posts'])
  }

  const handlePostSuccess = () => {
    setIsMakingPost(false)
    queryClient.invalidateQueries(['posts'])
  }

  if (isLoading) {
    return <p>Loading...</p>
  }

  if (error) {
    return <p>Error loading posts: {error.message}</p>
  }

  return (
    <HomeFeedPageContainer>
      <MainContentContainer>
        <FilterContainer>
          <DropdownMenu
            options={['Most Recent', 'Most Liked', 'Most Commented']}
            placeholder="Sort by: Most Recent"
            onOptionChange={handleFilterChange}
          />
        </FilterContainer>
        <ProfileAndThreadContainer>
          <StickyProfileContainer>
            <Profile
              onAllPostsClick={() => setIsMakingPost(false)}
              onMakePostClick={() => setIsMakingPost(true)}
            />
          </StickyProfileContainer>
          <PostContainer>
            {isMakingPost ? (
              <PostComposer onPostSuccess={handlePostSuccess} />
            ) : (
              <Thread posts={posts} />
            )}
          </PostContainer>
        </ProfileAndThreadContainer>
      </MainContentContainer>
    </HomeFeedPageContainer>
  )
}

export default HomeFeed

const ProfileAndThreadContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
  padding: 20px;
`

const StickyProfileContainer = styled.div`
  position: sticky;
  top: 20px;
  align-self: flex-start;

  @media (max-width: ${size.tablet}) {
    display: none;
  }
`

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  width: 100%;
  padding: 20px;
`

const PostContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  // padding: 20px;
`

const HomeFeedPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  height: fit-content;

  @media (max-width: ${size.mobile}) {
    flex-direction: column;
    align-items: center;
    justify-content: start;
  }
`

const MainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  // padding: 0 20px;
`
