import styled from 'styled-components'
import { device, size } from '../../../assets/styles/mediaBreakpoints'

// Base button styles
const BaseButton = styled.button`
  padding: 10px 20px;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
  @media ${device.mobile} {
    // Styles for mobile
    padding: 8px 16px;
    font-size: 14px;
  }

  @media ${device.tablet} {
    // Override for tablet
    padding: 10px 20px;
    font-size: 16px;
  }

  @media ${device.desktop} {
    // Override for desktop
    padding: 12px 24px;
    font-size: 18px;
  }
`

// Primary button styles (you can modify the colors and styles as needed)
export const PrimaryButton = styled(BaseButton)`
  font-size: 16px;
  background-color: ${(props) => props.color || '#2F632C'};
  color: white;
  width: 200px;
  height: 40px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;

  &:hover {
    background-color: #0056b3;
  }
  @media (max-width: ${size.mobile}) {
    width: 100px;
    font-size: 14px;
  }
`

// Secondary button styles (you can modify the colors and styles as needed)
export const SecondaryButton = styled(BaseButton)`
  padding: 20px 56px; 
  font-size: 18px; 
  color: #2F632C;
  background-color: #F3FADC;
  }
`

// Tertiary button styles (you can modify the colors and styles as needed)
export const TertiaryButton = styled(BaseButton)`
  padding: 15px 80px; 
  font-size: 18px; 
  background-color: transparent;
  color: #e3f9a6;
  border: 1px solid #e3f9a6;

  }
`
// Fourth button styles (you can modify the colors and styles as needed)
export const FourthButton = styled(BaseButton)`
  padding: 20px 56px; 
  font-size: 18px; 
  background-color: transparent;
  color: #0c0a08;
  border: 1px solid #218838;

  
  }
`
export const AuthenticationButton = styled.button`
  background: #2f632c;
  color: #fff;
  font-size: 1rem;
  min-width: 200px;
  min-height: 40px;
  border: none;
  border-radius: 16px;
  margin: 20px;
  cursor: pointer;

  @media (max-width: ${size.tablet}) {
    font-size: 0.75rem;
    min-width: 150px;
    min-height: 30px;
  }
`

export const SSOButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border-radius: 16px;
  border: 1px solid #2f632c;
  margin: 10px;
  min-width: 250px;
  min-height: 35px;
  cursor: pointer;

  @media (max-width: ${size.tablet}) {
    min-width: 200px;
    min-height: 30px;
  }
`
