import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import axiosInstance from '../../../../config/AxiosInstance'
import { useAuth } from '../../../../context/AuthContext'

const CommentComposer = ({ postId, parentId, onReplyAdded }) => {
  const [replyText, setReplyText] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const { userId } = useAuth()
  const [username, setUsername] = useState('')

  useEffect(() => {
    const fetchUsername = async () => {
      if (!userId) return
      try {
        const response = await axiosInstance.get(`/api/users/${userId}`)
        setUsername(response.data.username)
      } catch (error) {
        // console.error('Error fetching username:', error)
      }
    }

    fetchUsername()
  }, [userId])

  const handleInputChange = (e) => {
    setReplyText(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!replyText.trim()) return
    if (!userId) {
      setErrorMessage('User information not available')
      return
    }

    const postData = {
      data: {
        Text: replyText,
        PostID: postId,
        parent_reply: parentId ? { id: parentId } : null,
        Userid: userId,
      },
    }

    try {
      const response = await axiosInstance.post('/api/replies', postData)
      onReplyAdded({
        ...response.data.data,
        attributes: {
          ...response.data.data.attributes,
          Userid: {
            data: {
              attributes: {
                username,
              },
            },
          },
        },
      })
      setReplyText('')
      setSuccessMessage('Reply added successfully!')
      setErrorMessage('')

      setTimeout(() => {
        setSuccessMessage('')
      }, 3000)
    } catch (error) {
      // console.error('Error posting reply:', error)
      const errorMsg =
        error.response?.data?.message ||
        'Failed to add reply. Please try again.'
      setErrorMessage(errorMsg)
      setSuccessMessage('')
    }
  }

  return (
    <ReplyForm onSubmit={handleSubmit}>
      <ReplyTextArea
        value={replyText}
        onChange={handleInputChange}
        placeholder="Write a reply..."
      />
      <SubmitButton type="submit" disabled={!replyText.trim()}>
        Post Reply
      </SubmitButton>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
    </ReplyForm>
  )
}

CommentComposer.propTypes = {
  postId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onReplyAdded: PropTypes.func.isRequired,
}

CommentComposer.defaultProps = {
  parentId: null,
}

export default CommentComposer

const ReplyForm = styled.form`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`

const ReplyTextArea = styled.textarea`
  width: 100%;
  min-height: 60px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: vertical;
`

const SubmitButton = styled.button`
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`

const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
`

const SuccessMessage = styled.div`
  color: green;
  margin-top: 10px;
`
