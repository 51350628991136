import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md'
import React, { useState } from 'react'
import IconComponent from '../ui/Icon'
import { size } from '../../../assets/styles/mediaBreakpoints'
import {
  EMAIL_REGEX,
  MIN_PASSWORD_LENGTH,
  BORDER_COLOR,
  INPUT_TYPES,
} from '../../../features/feed/utils/Constants'

const Input = ({ title, inputType, value, onChange, footer }) => {
  const [isValidEmail, setIsValidEmail] = useState(true)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [isValidPassword, setIsValidPassword] = useState(true)

  const validateEmail = (event) => {
    if (inputType !== INPUT_TYPES.email) return
    const inputValue = event.target.value
    onChange(event)
    setIsValidEmail(EMAIL_REGEX.test(inputValue))
  }

  const validatePasswordLength = (event) => {
    if (inputType !== INPUT_TYPES.password) return
    const inputValue = event.target.value
    onChange(event)
    setIsValidPassword(inputValue.length >= MIN_PASSWORD_LENGTH)
  }

  const getBorderColor = () => {
    if (inputType !== INPUT_TYPES.email && inputType !== INPUT_TYPES.password)
      return '#2F632c'
    if (inputType === INPUT_TYPES.email && footer) return BORDER_COLOR.neutral
    if (inputType === INPUT_TYPES.email && isValidEmail)
      return BORDER_COLOR.valid
    if (inputType === INPUT_TYPES.password && isValidPassword)
      return BORDER_COLOR.valid
    return BORDER_COLOR.invalid
  }

  return (
    <ComponentContainer>
      <Title style={{ fontWeight: footer ? '700' : '0' }}>{title}</Title>
      <InputContainer
        style={{
          border: '1px solid '.concat(getBorderColor()),
          background: footer ? 'white' : 'transparent',
        }}
      >
        {inputType === 'password' ? (
          <>
            <StyledInput
              type={passwordVisible ? 'text' : 'password'}
              value={value}
              onChange={validatePasswordLength}
            />
            {passwordVisible ? (
              <VisibilityIcon
                onClick={() => setPasswordVisible(!passwordVisible)}
              />
            ) : (
              <VisibilityOffIcon
                onClick={() => setPasswordVisible(!passwordVisible)}
              />
            )}
          </>
        ) : (
          <>
            <StyledInput
              type={inputType}
              value={value}
              onChange={inputType === 'email' ? validateEmail : onChange}
            />
            {footer ? (
              <Paperplane
                iconName="mdi:paper-airplane"
                color="#2f632c"
                sizeIcon={35}
              />
            ) : null}
          </>
        )}
      </InputContainer>
      {inputType === 'email' && !isValidEmail ? (
        <div style={{ color: 'red' }}>Please enter a valid email address</div>
      ) : null}
      {!isValidPassword ? (
        <div style={{ color: 'red' }}>
          Password must be at least 8 characters long
        </div>
      ) : null}
    </ComponentContainer>
  )
}

Input.propTypes = {
  title: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  footer: PropTypes.bool,
}

Input.defaultProps = {
  footer: false,
}

export default Input

export const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  margin: 20px 0 20px 0;
  max-width: 330px;

  @media (max-width: ${size.tablet}) {
    font-size: 0.75rem;
    width: 220px;
  }
`

const Title = styled.div`
  flex-shrink: 0;
  font-size: 1rem;
  padding-bottom: 5px;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  flex-shrink: 1;
  border: 1px solid;
  border-radius: 5px;
  width: 100%;
  height: 30px;
`

const StyledInput = styled.input`
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0 10px 0 10px;
  width: 100%;
  height: 90%;
  font-size: 1rem;
  outline: none;
`

const VisibilityIcon = styled(MdOutlineVisibility)`
  width: 30px;
  height: 30px;
  margin: 3px;
  cursor: pointer;
`

const VisibilityOffIcon = styled(MdOutlineVisibilityOff)`
  width: 30px;
  height: 30px;
  margin: 3px;
  cursor: pointer;
`

const Paperplane = styled(IconComponent)`
  cursor: pointer;
`
