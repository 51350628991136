import React from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import Header from './Header'
import Footer from './Footer'

// This is the Layout component which wraps around the main content of our pages.
// It includes a header and footer that are displayed on every page.
// The <Outlet /> component is used here as a placeholder to render the current route's component.

const Layout = () => {
  return (
    <BodyContainer>
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </BodyContainer>
  )
}

export default Layout

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  flex: 1;
`
