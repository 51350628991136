import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import IconComponent from '../../../components/common/ui/Icon'
import { size } from '../../../assets/styles/mediaBreakpoints'
import { PrimaryButton } from '../../../components/common/ui/Button'
import {
  T11RContent,
  SpanSmall,
  T6Content,
} from '../../../components/common/ui/Typography'

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long' }
  return new Date(dateString).toLocaleDateString('en-US', options)
}

const ResearchPreview = ({ id, date, title, abstract, author }) => {
  const navigate = useNavigate()

  const handleViewClick = () => {
    navigate(`/research/${id}`)
  }

  return (
    <PreviewWrapper>
      <ResearchContainer>
        <Header>
          <IconComponent
            iconName="material-symbols:timelapse-outline"
            color="#2F632C"
            sizeIcon={24}
            style={{ margin: 0 }}
          />
          <SpanSmall margin="0 0 0 10px">{formatDate(date)}</SpanSmall>
        </Header>
        <ContentWrapper>
          <TextsWrapper>
            <T6Content color="#0a0a0a">{title}</T6Content>
            <AbstractContainer>{abstract}</AbstractContainer>
          </TextsWrapper>
        </ContentWrapper>
        <BottomWrapper>
          <IconComponent
            iconName="ic:outline-people"
            color="#2F632C"
            sizeIcon={24}
            style={{ margin: '0 5px 0 0' }}
          />
          <T11RContent>{author.name}</T11RContent>
        </BottomWrapper>
      </ResearchContainer>
      <ButtonContainer>
        <PrimaryButton onClick={handleViewClick}>View</PrimaryButton>
      </ButtonContainer>
    </PreviewWrapper>
  )
}

ResearchPreview.propTypes = {
  id: PropTypes.string.isRequired,
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  abstract: PropTypes.string.isRequired,
}

export default ResearchPreview
const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px;
  position: relative;
  align-items: center;
  margin: auto;
  max-width: 85%;
  border-bottom: 1px solid #8a8a8a;

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: ${size.tablet}) {
    max-width: 100%;
    flex-direction: column;
    gap: 20px;
  }
`

const ResearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 65%;
  gap: 10px;
  @media (max-width: ${size.tablet}) {
    max-width: 100%;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${size.desktop}) {
    flex-direction: row;
  }
`

const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const AbstractContainer = styled.div`
  margin: 10px 0 0 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  height: 2.4em;
`

const Header = styled.div`
  display: flex;
  align-items: center;
`

const BottomWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: ${size.desktop}) {
    flex-direction: row;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
