import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import SmallHero from '../../../components/common/ui/SmallHero'
import Thread from '../../../components/common/unique/Thread'
import ArticleList from '../../../features/feed/components/ArticleList'
import {
  T1Content,
  T3Content,
  H4Text,
  HomeSDG,
  T6Content,
} from '../../../components/common/ui/Typography'
import Dropdown from '../../../components/common/form/Dropdown'
import { BreadcrumbItem } from '../../../components/common/form/Address'
import axiosInstance from '../../../config/AxiosInstance'

const SDG = () => {
  const { id } = useParams()
  const [sdg, setSdg] = useState(null)
  const [loading, setLoading] = useState(true)
  const [apiError, setApiError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await axiosInstance.get(
          `/api/sdg-icons/${id}?populate=*`,
        )
        if (response.status !== 200)
          throw new Error(response.data.message || 'Could not fetch data')

        setSdg(response.data.data)
      } catch (error) {
        setApiError(error.message)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [id])

  if (loading) return <div>Loading...</div>
  if (apiError) return <div>Error: {apiError}</div>
  if (!sdg) return <div>No data found</div>

  return (
    <div>
      {sdg.attributes.Image && (
        <SmallHero imageUrl={sdg.attributes.Image.data.attributes.url} />
      )}
      <ContentsContainer>
        <HomeSDG>
          <BreadcrumbItem name="Home" path="/" isLast={false} />
          <BreadcrumbItem name={`SDG ${id}`} path={`/sdg/${id}`} isLast />
        </HomeSDG>
        <T3Content>
          Based on your interests, you&apos;re a great match for&nbsp;
          {sdg.attributes.Title}
        </T3Content>
        <StyledH4Text>Overview</StyledH4Text>
        <T6Content>{sdg.attributes.Description}</T6Content>
        <StyledH4Text>The community</StyledH4Text>
        <T1Content>Leave us a comment</T1Content>
        <textarea
          placeholder="Type here..."
          style={{
            width: '95%',
            minHeight: '50px',
            margin: '20px 0 0 0',
          }}
        />
        <Thread />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <StyledH4Text>Challenges</StyledH4Text>
          <Dropdown
            options={['Newest', 'Active', 'Popular']}
            placeholder="Sort by: Newest"
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <StyledH4Text>Recent research articles</StyledH4Text>
          <Dropdown
            options={['Latest Research', 'Peer Reviews', 'Blog Posts']}
            placeholder="Show More"
          />
        </div>
        <ArticleList />
      </ContentsContainer>
    </div>
  )
}

const ContentsContainer = styled.div`
  padding: 50px 60px;
`

const StyledH4Text = styled(H4Text)`
  margin: 80px 0 40px 0;
`

export default SDG
