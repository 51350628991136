import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { device } from '../../../assets/styles/mediaBreakpoints'

const DatabaseTable = ({ data }) => {
  const renderTableHeader = () => {
    if (data.length > 0) {
      return Object.keys(data[0]).map((key) => (
        <th key={key}>{key}</th> // Using key names as keys
      ))
    }
    return null // Ensure all code paths return a value
  }

  const renderTableBody = () => {
    return data.map((row, rowIndex) => (
      // Assuming each row has a unique 'id'. If not, find a unique aspect or create one.
      <TableRow key={row.id || `row-${rowIndex}`}>
        {Object.entries(row).map(([key, val]) => {
          // Attempt to create a more unique key if possible
          const cellKey = `${key}-${val}-${rowIndex}`
          return (
            <TableCell key={cellKey} data-label={key}>
              {val}
            </TableCell>
          )
        })}
      </TableRow>
    ))
  }

  return (
    <StyledTable>
      <TableHeader>
        <tr>{renderTableHeader()}</tr>
      </TableHeader>
      <tbody>{renderTableBody()}</tbody>
    </StyledTable>
  )
}

DatabaseTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default DatabaseTable
const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;

  th,
  td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  @media ${device.desktop} {
    // Customizations for mobile devices
    th,
    td {
      padding: 4px;
    }
  }

  @media ${device.tablet} {
    // Adjustments for tablet screens
    th,
    td {
      padding: 8px;
    }
  }

  // Further customizations can be added for desktop and larger devices
`

const TableCell = styled.td`
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;

  @media ${device.desktop} {
    display: block;
    width: 100%;

    &:before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      margin-right: 10px; // Ensure some spacing between the label and the content
    }
  }

  @media ${device.tablet} {
    display: table-cell; // Revert to default table cell display
    width: auto; // Reset width to auto for proper table layout

    &:before {
      content: none; // Remove the content of the :before pseudo-element
    }
  }
`

// Ensure the table rows and headers look correct on larger screens
const TableRow = styled.tr`
  @media ${device.desktop} {
    display: block;
    width: 100%;
    border-bottom: 2px solid #ddd;

    &:last-child {
      border-bottom: none;
    }
  }

  @media ${device.tablet} {
    display: table-row; // Ensure table rows display correctly on tablet and above
    border-bottom: 1px solid #ddd;
  }
`

// Adjust the header to ensure it displays correctly on tablet and desktop
const TableHeader = styled.thead`
  background-color: #4caf50;
  color: white;

  @media ${device.desktop} {
    display: block;
    width: 100%;
  }

  @media ${device.tablet} {
    display: table-header-group; // Reset to default display for table headers
  }

  th {
    @media ${device.desktop} {
      display: block;
    }

    @media ${device.tablet} {
      display: table-cell;
    }
  }
`
