import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-scroll'
import IconComponent from '../ui/Icon'
import { size } from '../../../assets/styles/mediaBreakpoints'

const VerticalLinkList = ({ links }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const listContainerRef = useRef()

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const handleLinkClick = () => {
    setIsMenuOpen(false)
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        listContainerRef.current &&
        !listContainerRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <>
      <HamburgerIcon
        iconName="quill:hamburger"
        color="#0a0a0a"
        sizeIcon={30}
        onClick={toggleMenu}
      />
      <ListContainer open={isMenuOpen} ref={listContainerRef}>
        {links.map((link) => (
          <StyledLink
            key={link.id}
            to={link.to}
            spy
            smooth
            offset={-70}
            duration={500}
            onClick={handleLinkClick}
          >
            {link.title}
          </StyledLink>
        ))}
      </ListContainer>
    </>
  )
}

VerticalLinkList.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default VerticalLinkList
const ListContainer = styled.div`
  background-color: #f0efeb;
  padding: 25px;
  width: auto;
  max-width: 400px;
  display: inline-block;
  float: right;
  margin: 0 30px;

  @media (min-width: ${size.desktopLarge}) {
    margin: 0 50px;
  }

  @media (max-width: ${size.desktop}) {
    padding: 15px;
  }

  @media (max-width: ${size.tablet}) {
    transform: translateX(${(props) => (props.open ? '0' : '100%')});
    position: fixed;
    width: 40%;
    top: 150px;
    right: -20px;
    display: ${(props) =>
      props.open ? 'block' : 'none'}; // Show or hide based on the open prop
    font-size: 16px;
    padding: 5px;
  }
`

const StyledLink = styled(Link)`
  display: block;
  color: #b64a07;
  padding: 20px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: ${size.tablet}) {
    padding: 5px;
    margin: 10px;
  }
`

const HamburgerIcon = styled(IconComponent)`
  cursor: pointer;
  display: none; // Hidden by default

  @media (max-width: ${size.tablet}) {
    display: block;
    position: absolute;
    top: 160px;
    right: 10px;
  }
`
