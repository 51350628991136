import React from 'react'
import RadioButton from '../../components/common/form/RadioButton'
import Dropdown from '../../components/common/form/Dropdown'
import DatabaseTable from '../../components/common/ui/DatabaseTable'

const About = () => {
  // Sample data for the table
  const tableData = [
    { Name: 'Alice', Age: 30, Country: 'USA' },
    { Name: 'Bob', Age: 25, Country: 'UK' },
    // ... more rows as needed
  ]

  return (
    <div>
      <h1>About Page</h1>
      <form>
        <RadioButton
          label="Become an ambassador"
          name="group1"
          defaultChecked
        />
        <RadioButton label="Option 2" name="group1" />
        <RadioButton label="Option 3" name="group1" />
      </form>
      {/* Add the DatabaseTable component here */}
      <DatabaseTable data={tableData} />
      <Dropdown
        options={['Newest', 'Active', 'Popular']}
        placeholder="Sort by: Newest"
      />
    </div>
  )
}

export default About
